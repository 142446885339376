import { useState } from 'react';

import { useFirebaseContext } from '../providers/FirebaseProvider';

const groupPlansByMedicalGroup = (plans) => {
  const medicalGroupsMap = plans.reduce((acc, plan) => {
    if (acc[plan.medicalGroupId]) return acc;
    acc[plan.medicalGroupId] = {
      name: plan.medicalGroupName,
      id: plan.medicalGroupId,
      formularyId: plan.formularyId,
    };
    return acc;
  }, {});
  return Object.values(medicalGroupsMap);
};

const usePlans = () => {
  const firebase = useFirebaseContext();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPlans = async () => {
    setLoading(true);
    const currentPlanNameDoc = await firebase.firestore
      .collection('currentCollections')
      .get();
    const currentPlanData = currentPlanNameDoc.docs.map((d) => d.data());
    const currentPlanName = currentPlanData.length
      ? currentPlanData[0].currentCollectionName
      : null;
    const plansDoc = await firebase.firestore
      .collection('mmitPlansCurrent')
      .doc(currentPlanName)
      .collection('currentPlans')
      .get();
    const allPlans = plansDoc.docs.map((d) => d.data());
    const groupedPlans = groupPlansByMedicalGroup(allPlans);
    setPlans(groupedPlans);
    setLoading(false);
  };

  return {
    fetchPlans,
    loading,
    plans,
  };
};

export default usePlans;
