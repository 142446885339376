import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { columnWidths } from './UserList.constants';
import { linkStyles } from '../Link';
import { ListBodyRow, ListBodyRowContent, DataColumn } from '../List';
import { userPropType } from '../../propTypes';
import { useAuthContext } from '../../providers/AuthProvider';
import * as typography from '../../styles/typography';

const RowContent = styled(ListBodyRowContent)`
  margin-left: 0;
  margin-right: 0;
`;
const TextButton = styled.span`
  ${typography.bold};
  ${linkStyles};
`;
const BoldColumn = styled(DataColumn)`
  ${typography.bold};
  color: ${({ theme }) => theme.foreground};
`;

const UserListRow = ({ onEditUser, onRemoveUser, user }) => {
  const { sendPasswordResetEmail } = useAuthContext();

  return (
    <ListBodyRow>
      <RowContent>
        <BoldColumn width={columnWidths.displayName}>
          {user.displayName}
        </BoldColumn>
        <DataColumn width={columnWidths.email}>{user.email}</DataColumn>
        <DataColumn width={columnWidths.editButtons}>
          <TextButton onClick={onEditUser}>EDIT</TextButton>
          &nbsp;|&nbsp;
          <TextButton onClick={onRemoveUser}>REMOVE</TextButton>
        </DataColumn>
        <DataColumn width={columnWidths.passwordReset}>
          <TextButton onClick={() => sendPasswordResetEmail(user.email)}>
            Send Email to Set Password
          </TextButton>
        </DataColumn>
      </RowContent>
    </ListBodyRow>
  );
};

UserListRow.propTypes = {
  onEditUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  user: userPropType.isRequired,
};

UserListRow.defaultProps = {};

export default UserListRow;
