import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { padding } from 'polished';

import { ChevronUp } from './Icons';
import { linkStyles } from './Link';
import PageFooter from './PageFooter';
import * as FOOTER_CONTENT from '../constants/footerContent';
import { darkTheme } from '../styles/themes';
import * as typography from '../styles/typography';
import { appGutters, footerHeight } from '../styles/constants';

const PRESCRIBING_INFO_LINK =
  'https://www.regeneron.com/downloads/eylea_fpi.pdf';

const PRESCRIBING_INFO_LINK_HD = null; // TODO: add prescribing info link for EYLEA HD

const AppFooterWrapper = styled.div`
  ${(props) => padding(0, appGutters, props.sticky ? 0 : '16px')};
  background-color: ${({ theme }) => theme.background};
  border-top: 6px solid ${({ theme }) => theme.highlight};
  bottom: 0;
  color: ${({ theme }) => theme.text};
  cursor: ${(props) => (props.isExpanded ? 'auto' : 'pointer')};
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.sticky ? footerHeight : 'fit-content')};
  min-height: ${footerHeight};
  max-height: ${(props) => (props.sticky ? footerHeight : 'fit-content')};
  overflow: ${(props) => (props.sticky ? 'hidden' : 'visible')};
  position: ${(props) => (props.sticky ? 'fixed' : 'relative')};
  z-index: ${(props) => (props.sticky ? 10 : 5)};
  & ul {
    padding-inline-start: 20px;
    & li {
      padding-bottom: 2px;
    }
  }
  & p,
  ul {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
`;
const BigChevron = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 16px 0 0;
  width: fit-content;
  & svg {
    transform: rotate(${(props) => (props.isExpanded ? '180deg' : '0deg')});
    & * {
      fill: ${({ theme }) => theme.accent};
      stroke: ${({ theme }) => theme.accent};
    }
  }
`;

const BoldCaps = styled.strong`
  text-transform: uppercase;
  ${typography.h6};
  ${typography.bold};
`;

const BoldIsiText = styled.div`
  font-size: 1rem;
  ${typography.bold};
`;

const FooterText = styled.div`
  font-size: 0.9rem;
`;

const Bullet = styled.li`
  margin-bottom: 8px;
  font-size: 1rem;
  ${typography.bold};
`;

const NestedBullet = styled(Bullet)`
  margin-left: 10px;
`;

const PrescribingInfo = styled.div`
  margin-top: 15px;
  ${typography.bold};
  color: ${({ theme }) => theme.foreground};
  & > a {
    ${linkStyles};
    text-decoration: underline;
  }
`;

const AppFooter = forwardRef(
  ({ className, isExpanded, onToggleExpanded, sticky }, ref) => (
    <ThemeProvider theme={darkTheme}>
      <AppFooterWrapper
        className={className}
        isExpanded={isExpanded}
        onClick={() => !isExpanded && onToggleExpanded()}
        ref={ref}
        sticky={sticky}
      >
        <BigChevron isExpanded={isExpanded} onClick={onToggleExpanded}>
          {ChevronUp}
        </BigChevron>
        <p>
          <BoldCaps>
            Important Safety Information for EYLEA HD and EYLEA
          </BoldCaps>
        </p>
        <FooterText>
          {FOOTER_CONTENT.BULLETPOINTS.map(({ title, bullets }, index) => (
            <React.Fragment key={index}>
              <BoldCaps>{title}</BoldCaps>
              <ul>
                {bullets.map((b, i) => (
                  <React.Fragment key={i}>
                    <Bullet key={i}>{b.text}</Bullet>
                    {b.bullets && (
                      <ul>
                        {b.bullets.map((nestedBullet, j) => (
                          <NestedBullet key={j}>
                            {nestedBullet.text}
                          </NestedBullet>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </React.Fragment>
          ))}
          {FOOTER_CONTENT.COPY.map(({ title, eyleaHD, eylea }, index) => (
            <React.Fragment key={index}>
              <BoldCaps>{title}</BoldCaps>
              <BoldIsiText>
                <p>{eyleaHD}</p>
              </BoldIsiText>
              <BoldIsiText>
                <p>{eylea}</p>
              </BoldIsiText>
            </React.Fragment>
          ))}
        </FooterText>
        <PrescribingInfo>
          Please see full Prescribing Information for&nbsp;
          <a href={PRESCRIBING_INFO_LINK_HD} target="_blank" rel="noreferrer">
            EYLEA HD
          </a>
          &nbsp;and&nbsp;
          <a href={PRESCRIBING_INFO_LINK} target="_blank" rel="noreferrer">
            EYLEA
          </a>
          .
        </PrescribingInfo>
        <PageFooter />
      </AppFooterWrapper>
    </ThemeProvider>
  )
);

// we need this because we're using React.forwardRef()
AppFooter.displayName = 'AppFooter';
AppFooter.propTypes = {
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  onToggleExpanded: PropTypes.func.isRequired,
  sticky: PropTypes.bool,
};

export default AppFooter;
