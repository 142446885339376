import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PracticeListRow from './PracticeListRow';
import PracticeForm from './PracticeForm';
import { columnWidths, tableLeftSpacing } from './PracticeList.constants';
import { usePracticeApi } from '../../api/practice';
import useRowForm, { NEW_ROW_ID } from '../../hooks/useRowForm';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import FullWidthWrapper from '../FullWidthWrapper';
import LoadingSpinner from '../LoadingSpinner';
import { useAuthContext } from '../../providers/AuthProvider';

import {
  LIST_PADDING_X,
  ListTitleBar,
  List,
  DataColumn,
  TitleColumn,
} from '../List';

const StyledTitleBar = styled(ListTitleBar)`
  padding-left: calc(${tableLeftSpacing} + ${LIST_PADDING_X});
  background-color: #b5b4b4;
`;

const PracticeList = ({ onCloseNewRow, showNewRow }) => {
  const { user } = useAuthContext();
  const {
    CreatePractice,
    EditPractice,
    GetPractices,
    RemovePractice,
  } = usePracticeApi();
  const {
    activateEditMode,
    deactivateEditMode,
    clearDeleteConfirmation,
    data: practices,
    deleteConfirmation,
    editModeRows,
    handleCreate,
    handleDelete,
    handleUpdate,
    isLoading,
    pendingChanges,
    showDeleteConfirmation,
  } = useRowForm({
    apiMethods: {
      create: (practice) => CreatePractice(practice, user.uid),
      read: GetPractices,
      update: EditPractice,
      delete: RemovePractice,
    },
    mapFetchedData: (data) => {
      return data.map((practice) => ({
        ...practice.practiceDetails,
        rbm: practice.rbm,
        id: practice.id,
      }));
    },
    onCreateSuccess: () => {
      onCloseNewRow();
    },
  });

  const [expandedRow, setExpandedRow] = useState([]);

  return (
    <FullWidthWrapper>
      <StyledTitleBar>
        <TitleColumn width={columnWidths.displayName}>Practice</TitleColumn>
        <DataColumn width={columnWidths.address1}>Address</DataColumn>
        <DataColumn width={columnWidths.address2}>Address 2</DataColumn>
        <DataColumn width={columnWidths.city}>City</DataColumn>
        <DataColumn width={columnWidths.usState}>State</DataColumn>
        <DataColumn width={columnWidths.zip}>Zip</DataColumn>
        <DataColumn width={columnWidths.phone}>Phone</DataColumn>
        <DataColumn width={columnWidths.editButtons} />
        <DataColumn width={columnWidths.expandButton} />
      </StyledTitleBar>
      {isLoading && <LoadingSpinner size={100} />}
      {!isLoading && (
        <List>
          {showNewRow && (
            <PracticeForm
              isSaving={pendingChanges.includes(NEW_ROW_ID)}
              onCancel={onCloseNewRow}
              onSave={handleCreate}
            />
          )}
          {!!practices.length &&
            practices.map((p, i) =>
              editModeRows.includes(p.id) ? (
                <PracticeForm
                  initialValue={p}
                  isSaving={pendingChanges.includes(p.id)}
                  key={p.id}
                  onCancel={() => deactivateEditMode(p)}
                  onSave={handleUpdate}
                />
              ) : (
                user.uid === p.rbm && (
                  <PracticeListRow
                    isExpanded={expandedRow === p.id}
                    key={p.id}
                    onCollapse={() => setExpandedRow(null)}
                    onEditPractice={() => activateEditMode(p)}
                    onExpand={() => setExpandedRow(p.id)}
                    onRemovePractice={() => showDeleteConfirmation(p)}
                    practice={p}
                  />
                )
              )
            )}
        </List>
      )}
      <ConfirmDeleteModal
        isOpen={deleteConfirmation.isOpen}
        deleteTargetName="Practice"
        onContinue={() => handleDelete()}
        onCancel={clearDeleteConfirmation}
      />
    </FullWidthWrapper>
  );
};

PracticeList.propTypes = {
  noResultsText: PropTypes.string,
  onCloseNewRow: PropTypes.func,
  showNewRow: PropTypes.bool,
};

export default PracticeList;
