import { lighten } from 'polished';

// core colors
export const teal = '#60cdcb';
export const lightBlue = '#ADDFED';
export const darkBlue = '#00446b';
export const darkBlue_10 = '#00446B1A';
export const yellow = '#ffd100';
export const red = '#ee1111';
export const green = '#2DA870';

// color variants
export const fadedBlue = '#296283';
export const blueGrey = '#85a6b9';
export const darkerBlue = '#003454';
export const lightYellow = lighten(0.2, yellow);

export const greyscale = {
  50: '#fafafa',
  100: '#f0f0f0',
  200: '#d9d9d9',
  300: '#c3c3c3',
  400: '#a8a8a8',
  500: '#858585',
  600: '#707070',
  900: '#212121',
};
export const white = greyscale[50];
export const black = greyscale[900];
