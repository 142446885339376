/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import {
  columnWidths,
  tableLeftSpacing,
  userColumnWidths,
} from './PracticeList.constants';
import SuperUserForm from './SuperUserForm';
import SuperUserListRow from './SuperUserListRow';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import LoadingSpinner from '../LoadingSpinner';
import TextButton from '../TextButton';
import { ListBodyRow, ListBodyRowContent, DataColumn } from '../List';
import * as roles from '../../constants/roles';
import useRowForm, { NEW_ROW_ID } from '../../hooks/useRowForm';
import { practicePropType } from '../../propTypes';
import { darkTheme, lightTheme } from '../../styles/themes';
import * as typography from '../../styles/typography';
import { usePracticeApi } from '../../api/practice';
import { useUserApi } from '../../api/user';
import { useAppContext } from '../../providers/AppProvider';

const RowContent = styled(ListBodyRowContent)`
  margin-left: ${tableLeftSpacing};
  margin-right: 0;
`;

// SUB-LIST
// the wacky max height thing makes the transition smooth by calculating the correct value
// based on the number of list items and the width of the window (to account for line wrap)
const getHeightPerItem = () => {
  if (document.body.clientWidth > 1325) return 35;
  if (document.body.clientWidth > 850) return 50;
  return 65;
};

const getHeaderHeight = () => {
  if (document.body.clientWidth > 1050) return 35;
  return 50;
};

const calcMaxHeightFromProps = ({ isExpanded, showNewRow, userCount }) => {
  if (!isExpanded) return '0px';
  const headerHeight = getHeaderHeight();
  const heightPerItem = getHeightPerItem();
  const newRowHeight = showNewRow ? 50 : 0;
  const maxHeight = heightPerItem * userCount + headerHeight + newRowHeight;
  return `${maxHeight}px`;
};

const PracticeUserList = styled.div`
  max-height: ${calcMaxHeightFromProps};
  overflow: hidden;
  transition: max-height 0.1s linear;
  ${typography.body};
  margin-bottom: ${(props) => (props.isExpanded ? '16px' : '0')};
  margin-left: ${tableLeftSpacing};
`;

const SubListHeader = styled.div`
  ${typography.bold};
  align-items: baseline;
  background-color: ${({ theme }) => theme.disabled};
  color: ${({ theme }) => theme.text};
  display: flex;
  max-width: 100%;
  padding: 8px 20px;
`;

const BoldColumn = styled(DataColumn)`
  ${typography.bold};
  color: ${({ theme }) => theme.foreground};
`;

const PracticeListRow = ({
  isExpanded,
  onCollapse,
  onEditPractice,
  onExpand,
  onRemovePractice,
  practice,
}) => {
  const { ListenToPracticeUserUpdates } = usePracticeApi();
  const { CreateUser, DeleteUser, EditUser } = useUserApi();
  const { setGlobalError } = useAppContext();

  const createUser = async (newUser) => {
    try {
      return await CreateUser(roles.SUPER_USER, newUser, practice.id);
    } catch (err) {
      setGlobalError({
        error: err,
        message: err.message,
      });
    }
  };
  const deleteUser = (userId) => DeleteUser(userId, practice.id);

  const [showNewRow, setShowNewRow] = useState(false);
  const {
    activateEditMode,
    deactivateEditMode,
    clearDeleteConfirmation,
    data: users,
    deleteConfirmation,
    editModeRows,
    handleCreate: handleCreateUser,
    handleDelete: handleDeleteUser,
    handleUpdate: handleUpdateUser,
    isLoading,
    pendingChanges,
    showDeleteConfirmation,
  } = useRowForm({
    apiMethods: {
      create: createUser,
      update: EditUser,
      delete: deleteUser,
      subscribe: (onSuccess) => {
        return ListenToPracticeUserUpdates(practice.id, onSuccess);
      },
    },
    isVisible: isExpanded,
    onCreateSuccess: () => {
      setShowNewRow(false);
    },
  });

  const handleDeletePractice = () => {
    onCollapse();
    onRemovePractice();
  };

  const superUsers = useMemo(() => {
    if (!users || !users.length) return [];
    return users.filter((u) => u.role === roles.SUPER_USER);
  }, [users]);

  return (
    <>
      <ThemeProvider theme={isExpanded ? darkTheme : lightTheme}>
        <ListBodyRow>
          <RowContent>
            <BoldColumn width={columnWidths.displayName}>
              {practice.displayName}
            </BoldColumn>
            <DataColumn width={columnWidths.address1}>
              {practice.address1}
            </DataColumn>
            <DataColumn width={columnWidths.address2}>
              {practice.address2}
            </DataColumn>
            <DataColumn width={columnWidths.city}>{practice.city}</DataColumn>
            <DataColumn width={columnWidths.usState}>
              {practice.usState}
            </DataColumn>
            <DataColumn width={columnWidths.zip}>{practice.zip}</DataColumn>
            <DataColumn width={columnWidths.phone}>{practice.phone}</DataColumn>
            <DataColumn width={columnWidths.editButtons}>
              <TextButton onClick={onEditPractice}>EDIT</TextButton>
              &nbsp;|&nbsp;
              <TextButton onClick={handleDeletePractice}>REMOVE</TextButton>
            </DataColumn>
            <DataColumn width={columnWidths.expandButton} justify="flex-end">
              <TextButton
                onClick={() => (isExpanded ? onCollapse() : onExpand())}
              >
                {isExpanded ? 'Close' : 'View/Add Managing Users'}
              </TextButton>
            </DataColumn>
          </RowContent>
        </ListBodyRow>
      </ThemeProvider>
      <PracticeUserList
        isExpanded={isExpanded}
        showNewRow={showNewRow}
        userCount={superUsers.length}
      >
        <ThemeProvider theme={darkTheme}>
          <SubListHeader>
            <DataColumn width={userColumnWidths.displayName}>
              Managing Users
            </DataColumn>
            <DataColumn width={userColumnWidths.email}>
              Email Address
            </DataColumn>
            <DataColumn width={userColumnWidths.editButtons}></DataColumn>
            <DataColumn
              width={userColumnWidths.passwordReset}
              justify="flex-end"
            >
              <TextButton
                disabled={showNewRow}
                onClick={() => setShowNewRow(true)}
              >
                ADD NEW MANAGING USER
              </TextButton>
            </DataColumn>
          </SubListHeader>
        </ThemeProvider>
        {isLoading && <LoadingSpinner size={100} />}
        {!isLoading && (
          <>
            {showNewRow && !pendingChanges.includes(NEW_ROW_ID) && (
              <SuperUserForm
                isSaving={pendingChanges.includes(NEW_ROW_ID)}
                onCancel={() => setShowNewRow(false)}
                onSave={handleCreateUser}
              />
            )}
            {superUsers.map((u, i) =>
              editModeRows.includes(u.id) ? (
                <SuperUserForm
                  initialValue={u}
                  isSaving={pendingChanges.includes(u.id)}
                  key={i}
                  onCancel={() => deactivateEditMode(u)}
                  onSave={handleUpdateUser}
                />
              ) : (
                <SuperUserListRow
                  key={i}
                  onEditUser={() => activateEditMode(u)}
                  onRemoveUser={() => showDeleteConfirmation(u)}
                  user={u}
                />
              )
            )}
          </>
        )}
      </PracticeUserList>
      {isExpanded && (
        <ConfirmDeleteModal
          isOpen={deleteConfirmation.isOpen}
          deleteTargetName="Managing User"
          onContinue={() => handleDeleteUser()}
          onCancel={clearDeleteConfirmation}
        />
      )}
    </>
  );
};

PracticeListRow.propTypes = {
  isExpanded: PropTypes.bool,
  onCollapse: PropTypes.func,
  onCloseNewRow: PropTypes.func,
  onEditPractice: PropTypes.func,
  onExpand: PropTypes.func,
  onRemovePractice: PropTypes.func,
  practice: practicePropType.isRequired,
};

export default PracticeListRow;
