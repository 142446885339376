import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../Button';
import Tooltip from '../Tooltip';
import * as typography from '../../styles/typography';

const Heading = styled.div`
  ${typography.h6};
  ${typography.bold};
  color: ${({ theme }) => theme.foreground};
`;

const Body = styled.p`
  font-size: 0.875rem;
`;

const SettingsTooltip = ({ anchorEl, isOpen, onClose, superUsers }) => {
  const emailString = superUsers.map((u) => u.email).join(',');
  return (
    <Tooltip
      anchorEl={anchorEl}
      height="120px"
      isOpen={isOpen}
      offset={{
        x: -10,
        y: -10,
      }}
      onClose={onClose}
      position={{
        x: 'left',
        y: 'bottom',
      }}
      width="380px"
    >
      <Heading>My Account</Heading>
      <Body>
        If you wish to make changes or have questions about your User Settings,
        please contact your Office Managing User.
      </Body>
      <a href={`mailto:${emailString}`} onClick={onClose}>
        <Button>SEND EMAIL</Button>
      </a>
    </Tooltip>
  );
};

SettingsTooltip.propTypes = {
  anchorEl: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  superUsers: PropTypes.array,
};

export default SettingsTooltip;
