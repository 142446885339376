import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { userColumnWidths, EMPTY_USER } from './PracticeList.constants';
import Input from '../Input';
import TextButton from '../TextButton';
import { ListRow, DataColumn } from '../List';
import * as regex from '../../constants/regex';

const SubListRow = styled(ListRow)`
  padding: 10px 20px;
`;
const inputMarginOffset = '8px';
const StyledInput = styled(Input)`
  & input {
    margin: 4px;
    max-width: calc(100% - ${inputMarginOffset});
  }
`;

const SuperUserForm = ({
  initialValue = EMPTY_USER,
  isSaving,
  onCancel,
  onSave,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [user, setUser] = useState(initialValue);

  const setUserField = (fieldName) => (event) =>
    setUser({
      ...user,
      [fieldName]: event.target.value,
    });

  useEffect(() => {
    setIsFormValid(
      [!!user.displayName, regex.email.test(user.email)].every(Boolean)
    );
  }, [user]);

  const handleSave = () => {
    if (!isFormValid) return;
    onSave(user);
  };

  const renderInput = (fieldName) => (
    <DataColumn
      width={`calc(${userColumnWidths[fieldName]} - ${inputMarginOffset})`}
    >
      <StyledInput
        inputProps={{
          onChange: setUserField(fieldName),
          value: user[fieldName],
        }}
      />
    </DataColumn>
  );

  return (
    <SubListRow>
      {renderInput('displayName')}
      {renderInput('email')}
      <DataColumn width={userColumnWidths.editButtons} justify="flex-end">
        <TextButton disabled={isSaving} onClick={onCancel}>
          Cancel
        </TextButton>
        &nbsp;|&nbsp;
        <TextButton disabled={!isFormValid || isSaving} onClick={handleSave}>
          Save
        </TextButton>
      </DataColumn>
      <DataColumn width={userColumnWidths.passwordReset} />
    </SubListRow>
  );
};

SuperUserForm.propTypes = {
  initialValue: PropTypes.object,
  isSaving: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default SuperUserForm;
