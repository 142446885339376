import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as colors from '../../styles/colors';
import { bold } from '../../styles/typography';
import {
  getChannelLabelById,
  getIndicationLabelById,
  getProductLabelById,
} from '../../helpers';
import { ListBodyRowContent, IconColumn, DataColumn } from '../List';
import { policyPropType } from '../../propTypes';

const MetaColumn = styled(DataColumn)`
  color: ${colors.darkBlue};
  font-size: 9px;
`;

const MetaList = styled.dl`
  text-transform: uppercase;
  margin: 0;
  & > * {
    display: inline-block;
  }
`;

const MetaLabel = styled.dt`
  ${bold}
  &:after {
    content: ':';
  }
`;

const MetaValue = styled.dd`
  margin: 0 8px 0 2px;
`;

const PolicyListRowMeta = ({ policy, showVisibleToPractice }) => {
  return (
    <ListBodyRowContent>
      {showVisibleToPractice && <IconColumn />}
      <IconColumn />
      <MetaColumn>
        <MetaList>
          <MetaLabel>Product</MetaLabel>
          <MetaValue>{getProductLabelById(policy.productId)}</MetaValue>

          <MetaLabel>Channel</MetaLabel>
          <MetaValue>{getChannelLabelById(policy.channelId)}</MetaValue>

          <MetaLabel>Indication</MetaLabel>
          <MetaValue>{getIndicationLabelById(policy.indicationId)}</MetaValue>

          <MetaLabel>State</MetaLabel>
          <MetaValue>{policy.states.join(', ')}</MetaValue>
        </MetaList>
      </MetaColumn>
    </ListBodyRowContent>
  );
};

PolicyListRowMeta.propTypes = {
  policy: policyPropType.isRequired,
  showVisibleToPractice: PropTypes.bool.isRequired,
};

export default PolicyListRowMeta;
