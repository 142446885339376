import styled from 'styled-components';

import { globalTransition } from '../styles/utils';
import * as typography from '../styles/typography';

const StyledButton = styled.button`
  ${typography.body};
  background-color: ${({ theme }) => theme.foreground};
  border: none;
  border-radius: 1px;
  color: ${({ theme }) => theme.background};
  cursor: pointer;
  font-weight: 700;
  padding: 6px 32px;
  text-transform: uppercase;
  transition: ${globalTransition('background-color')};
  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    cursor: default;
  }
`;

export default StyledButton;
