import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import * as colors from '../styles/colors';

const InputWrapper = styled.div`
  background-color: ${({ error, theme }) =>
    error ? colors.white : theme.input};
  border: ${({ error, theme }) =>
    error ? `3px solid ${theme.error}` : theme.inputBorder};
  border-radius: 1px;
  display: flex;
  flex-grow: 1;
  max-width: 100%;
`;
const StyledInput = styled.input`
  background-color: ${({ error, theme }) =>
    error ? colors.white : theme.input};
  border: none;
  color: ${({ error, theme }) => (error ? theme.error : theme.text)};
  flex-grow: 1;
  max-width: 100%;
  outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`;
const IconRight = styled.div`
  padding: 4px 0 4px 4px;
  & > * {
    max-height: 100%;
  }
`;

const Input = ({ dark, error, iconRight, inputProps, ...props }) => {
  return (
    <InputWrapper dark={dark} error={error} {...props}>
      <StyledInput dark={dark} error={error} {...inputProps} />
      {iconRight && <IconRight>{iconRight}</IconRight>}
    </InputWrapper>
  );
};

Input.propTypes = {
  dark: PropTypes.bool,
  error: PropTypes.bool,
  iconRight: PropTypes.any,
  inputProps: PropTypes.object,
};

export default Input;
