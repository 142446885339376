import { useCallback, useMemo, useState } from 'react';

import { usePolicyApi } from '../api/policy';
import { createStateArrayUtils } from '../helpers';
import { useAppContext } from '../providers/AppProvider';
import { useAuthContext } from '../providers/AuthProvider';

const isPolicyEqual = (a, b) =>
  ['id', 'indicationId', 'productId'].every((field) => a[field] === b[field]);

const isPolicyInCollection = (collection) => (policy) => {
  return collection.some((item) => isPolicyEqual(item, policy));
};
const addPolicyToCollection = (collection, updateFn) => async (policy) => {
  return await updateFn([...collection, policy]);
};
const removePolicyFromCollection = (collection, updateFn) => async (policy) => {
  return await updateFn(collection.filter((p) => !isPolicyEqual(p, policy)));
};
const createCollectionApi = (collection, updateFn) => ({
  add: addPolicyToCollection(collection, updateFn),
  includes: isPolicyInCollection(collection),
  remove: removePolicyFromCollection(collection, updateFn),
});

const usePolicySelections = () => {
  const { policies, practice, refetchPractice } = useAppContext();
  const { refetchUser } = useAuthContext();
  const { updateFavoritePolicies, updateVisiblePolicies } = usePolicyApi();
  const [pendingChanges, setPendingChanges] = useState([]);

  const pendingChangesUtils = useMemo(
    () => createStateArrayUtils(setPendingChanges, pendingChanges),
    [pendingChanges]
  );

  const favoritesApi = useMemo(
    () => createCollectionApi(policies.favorites, updateFavoritePolicies),
    [policies.favorites]
  );

  const visibleApi = createCollectionApi(
    policies.visible,
    updateVisiblePolicies
  );

  const toggleFavoritePolicy = async (policy) => {
    pendingChangesUtils.add(policy.id);
    if (!favoritesApi.includes(policy)) {
      if (!visibleApi.includes(policy) && !practice.allPoliciesVisible) {
        await visibleApi.add(policy);
        await refetchPractice();
      }
      await favoritesApi.add(policy);
    } else {
      await favoritesApi.remove(policy);
    }
    await refetchUser();
    pendingChangesUtils.remove(policy.id);
  };

  const toggleVisiblePolicy = async (policy) => {
    pendingChangesUtils.add(policy.id);
    if (!visibleApi.includes(policy)) {
      await visibleApi.add(policy);
    } else {
      if (favoritesApi.includes(policy)) {
        await favoritesApi.remove(policy);
        await refetchUser();
      }
      await visibleApi.remove(policy);
    }
    await refetchPractice();
    pendingChangesUtils.remove(policy.id);
  };

  const getIsPending = useCallback(
    (policyId) => pendingChanges.includes(policyId),
    [pendingChanges]
  );

  return {
    getIsPending,
    toggleFavoritePolicy,
    toggleVisiblePolicy,
  };
};

export default usePolicySelections;
