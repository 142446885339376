import React from 'react';
import { padding } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useRouteMatch } from 'react-router-dom';

import BannerAd from './BannerAd';
import PageFooter from './PageFooter';
import bannerTop from '../assets/images/EHD.23.04.0337_EPPP_BannerAds-EyeonAccess.com.png';
import bannerBottom from '../assets/images/EHD.23.04.0333_EPPP_BannerAds-Reverification.png';
import * as colors from '../styles/colors';
import {
  headerHeight,
  footerHeight,
  pageHorizontalPadding,
} from '../styles/constants';
import { siteMetadata } from '../constants/siteMetadata';
import { calcViewArea } from '../styles/utils';

const StyledPageFooter = styled(PageFooter)`
  margin-top: 4rem;
`;

const PageWrapper = ({ children, className }) => {
  const { path } = useRouteMatch();
  const { showBanners, title } = siteMetadata[path];

  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showBanners && (
        <BannerAd
          imgSrc={bannerTop}
          jobNumber={'09/2023 EHD.23.04.0337'}
          href={'https://eyeonaccess.com/'}
          alt={'EyeonAccess'}
        />
      )}
      {children}
      {showBanners && (
        <BannerAd
          imgSrc={bannerBottom}
          jobNumber={'09/2023 EHD.23.04.0333'}
          href={'https://www.eylea4ueportaldemo.com/'}
          alt={'EYLEA4U'}
        />
      )}
      <StyledPageFooter />
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

PageWrapper.defaultProps = {
  title: 'Payer Policy',
  className: '',
};

const pageTopPadding = '32px';
const pageBottomPadding = '24px';
const viewArea = calcViewArea(footerHeight, headerHeight);

const StyledPageWrapper = styled(PageWrapper)`
  ${padding(pageTopPadding, pageHorizontalPadding, pageBottomPadding)}
  align-items: center;
  background-color: ${colors.white};
  border-left: 1px solid ${colors.darkBlue};
  border-right: 1px solid ${colors.darkBlue};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: fit-content;
  min-height: ${viewArea};
`;

export default StyledPageWrapper;
