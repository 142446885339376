import styled from 'styled-components';
import * as colors from '../styles/colors';
import { h3 } from '../styles/typography';

const JumboButton = styled.button`
  ${h3}
  cursor: pointer;
  padding: 2rem 6rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.foreground};
  border: 4px solid ${({ theme }) => theme.accent};
  border-radius: 12px;
  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.disabled};
    border-color: ${colors.white};
  }
`;

export default JumboButton;
