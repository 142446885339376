import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Modal from './Modal';
import * as typography from '../styles/typography';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  color: ${({ theme }) => theme.foreground};
  & > * {
    margin: 8px 0;
  }
`;
const Heading = styled.div`
  ${typography.bold};
  ${typography.h4};
  color: ${({ theme }) => theme.error};
`;
const SubHeading = styled.div`
  ${typography.h6};
`;

const ErrorModal = ({ heading, isOpen, message, onClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} showCloseIcon>
      <ContentWrapper>
        <Heading>{heading}</Heading>
        <SubHeading>{message}</SubHeading>
        <Button onClick={onClose}>Continue</Button>
      </ContentWrapper>
    </Modal>
  );
};

ErrorModal.propTypes = {
  heading: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func,
};
ErrorModal.defaultProps = {
  heading: 'An error has occurred',
};

export default ErrorModal;
