import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from './LoadingSpinner';

const Overlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: black;
  background: rgba(0, 0, 0, 0.7);
  margin: 0;
  z-index: 800;
  transition: opacity 250ms ease-in;
  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  perspective: 1000px;
`;

const LoadingOverlay = ({ loading }) =>
  loading ? (
    <Overlay isLoading={loading}>
      <LoadingSpinner />
    </Overlay>
  ) : null;

LoadingOverlay.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingOverlay;
