import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom';

import {
  AllResultsContainer,
  ErrorText,
  FilterControls,
  IndicationBox,
  LetterFilter,
  LetterFilterSet,
  SelectionContainer,
  ProductContainer,
  RadioSet,
  ResultLink,
  ResultLinksContainer,
  ResultsWrapper,
  SearchBox,
  SectionLabel,
  SelectedResultsContainer,
  StyledWrapper,
  SubmitButton,
} from './PlanFilterContainer.components';
import Select from '../Select';
import getFilteredData from './getFilteredData';
import IndicationRadio from './IndicationRadio';
import PlanItem from './PlanItem';
import Button from '../Button';
import { MagnifierLight } from '../Icons';
import PageHeading from '../PageHeading';
import { usePolicyApi } from '../../api/policy';
import routes from '../../constants/routes';
import {
  ALPHABET,
  MEDICATION_OPTIONS,
  INDICATION_OPTIONS,
} from '../../constants';
import { createStateArrayUtils } from '../../helpers';
import { useAppContext } from '../../providers/AppProvider';
import * as colors from '../../styles/colors';

const PlanFilterContainer = ({ data, pageLabel }) => {
  const { downloadPolicyDocuments, fetchPolicyLinks } = usePolicyApi();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [product, setProduct] = useState('');
  const [indication, setIndication] = useState('');
  const { fetchPlans, plans, setIsLoading } = useAppContext();
  const [letterFilter, setLetterFilter] = useState('');
  const [textFilter, setTextFilter] = useState('');
  // going to add debounce later
  // const [debouncedTextFilter] = useDebounce(textFilter, 500);
  const [selections, setSelections] = useState([]);
  const [pendingDownloads, setPendingDownloads] = useState([]);
  const pendingDownloadsUtils = createStateArrayUtils(
    setPendingDownloads,
    pendingDownloads
  );

  useEffect(() => {
    // only do this when the component is mounted
    fetchPlans();
  }, []);

  useEffect(() => {
    const isIndicationAvailable = product?.indications
      ?.map((i) => i.label)
      .includes(indication);
    if (!isIndicationAvailable) {
      setIndication('');
    }
  }, [product]);

  const isFormValid = !!indication && !!selections.length;
  const filteredData = getFilteredData(plans, { letterFilter, textFilter });

  const renderRadio = (label) => (
    <IndicationRadio
      active={indication === label}
      key={label}
      label={label}
      onClick={() => setIndication(label)}
    />
  );

  const getIsLetterFilterEnabled = (letter) => {
    return plans.map((i) => i.name.slice(0, 1)).includes(letter);
  };
  const getIsItemSelected = (item) => {
    return selections.find((i) => i.id === item.id);
  };

  const toggleSelection = (item) => {
    if (getIsItemSelected(item)) {
      setSelections(selections.filter((i) => i.id !== item.id));
    } else {
      setSelections([...selections, item]);
    }
  };

  const toggleLetterFilter = (letter) => {
    if (!getIsLetterFilterEnabled(letter)) return;
    if (letterFilter === letter) {
      setLetterFilter('');
    } else {
      setLetterFilter(letter);
    }
  };

  const handleChangeInput = useCallback((e) => setTextFilter(e.target.value), [
    setTextFilter,
  ]);

  const handleSubmitSelection = async () => {
    const selectedIndication = INDICATION_OPTIONS.find(
      (o) => o.label === indication
    );
    if (!selectedIndication) return;
    setIsLoading(true);
    const decoratedSelections = await fetchPolicyLinks(
      selectedIndication.value,
      selections,
      product.label
    );
    const filteredSelections =
      decoratedSelections?.map((sel) => ({
        ...sel,
        docs:
          sel.docs?.filter((doc) => {
            if (path.includes(routes.findPolicy)) return doc.isPolicy;
            if (path.includes(routes.findPriorAuth)) return doc.isForm;
            return false;
          }) ?? [],
      })) ?? [];
    setSelections(filteredSelections);
    history.push(`${path}/results`);
    setIsLoading(false);
  };

  const handleFileDownload = async (docData, index) => {
    if (pendingDownloads.includes(index)) return;
    pendingDownloadsUtils.add(index);
    await downloadPolicyDocuments(docData);
    pendingDownloadsUtils.remove(index);
  };

  return (
    <StyledWrapper>
      <Route exact path={path}>
        <PageHeading fullWidth>Find a {pageLabel}</PageHeading>
        <SelectionContainer>
          <ProductContainer>
            <Select
              backgroundColor={product?.color}
              value={product}
              placeholder="Select product:"
              options={MEDICATION_OPTIONS}
              onChange={setProduct}
            />
          </ProductContainer>
          <IndicationBox>
            <SectionLabel>
              {product
                ? 'Select indication:'
                : 'Please choose a product on the left.'}
            </SectionLabel>
            <RadioSet>
              {product?.indications?.map((o) => renderRadio(o.label))}
            </RadioSet>
          </IndicationBox>
        </SelectionContainer>
        <FilterControls>
          <SectionLabel>Select Health Insurance Plan(s)</SectionLabel>
          <LetterFilterSet>
            {ALPHABET.map((letter, index) => (
              <div key={index}>
                <LetterFilter
                  active={letter === letterFilter}
                  enabled={getIsLetterFilterEnabled(letter)}
                  onClick={() => toggleLetterFilter(letter)}
                >
                  {letter}
                </LetterFilter>
                {index !== ALPHABET.length - 1 && '|'}
              </div>
            ))}
          </LetterFilterSet>
          <SearchBox
            iconRight={MagnifierLight}
            inputProps={{
              onChange: handleChangeInput,
              value: textFilter,
            }}
          />
        </FilterControls>
        <ResultsWrapper>
          <AllResultsContainer>
            {!letterFilter && !textFilter
              ? null
              : filteredData.map((item, index) => (
                  <PlanItem
                    disabled={!!getIsItemSelected(item)}
                    key={index}
                    label={item.name}
                    onSelect={() => toggleSelection(item)}
                    selected={!!getIsItemSelected(item)}
                  />
                ))}
          </AllResultsContainer>
          <SelectedResultsContainer>
            {selections.map((item, index) => (
              <PlanItem
                key={index}
                label={item.name}
                onSelect={() => toggleSelection(item)}
                selected
              />
            ))}
          </SelectedResultsContainer>
        </ResultsWrapper>
        <SubmitButton
          color={colors.darkBlue}
          disabled={!isFormValid}
          onClick={handleSubmitSelection}
          textColor={colors.white}
        >
          Submit
        </SubmitButton>
      </Route>
      <Route exact path={`${path}/results`}>
        {!selections.length && <Redirect to={path} />}
        <PageHeading fullWidth>{pageLabel} Results</PageHeading>
        <ResultLinksContainer>
          {selections.map((item, index) => (
            <ResultLink key={index}>
              {item.name}
              {!!item.docs?.length && (
                <Button
                  color={colors.darkBlue}
                  disabled={pendingDownloads.includes(index)}
                  onClick={() => handleFileDownload(item.docs)}
                  textColor={colors.white}
                >
                  Download Documents
                </Button>
              )}
              {!item.docs?.length && (
                <ErrorText>Documents not available</ErrorText>
              )}
            </ResultLink>
          ))}
        </ResultLinksContainer>
      </Route>
    </StyledWrapper>
  );
};

PlanFilterContainer.propTypes = {
  data: PropTypes.array,
  pageLabel: PropTypes.string,
};

export default PlanFilterContainer;
