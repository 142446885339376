export const BULLETPOINTS = [
  {
    title: 'Contraindications',
    bullets: [
      {
        text: `EYLEA HD and EYLEA are contraindicated in patients with 
               ocular or periocular infections, active intraocular inflammation, 
               or known hypersensitivity to aflibercept or to any of the excipients
               in EYLEA HD or EYLEA.`,
      },
    ],
  },
  {
    title: 'Warnings and Precautions',
    bullets: [
      {
        text: `Intravitreal injections, including those with aflibercept, have been associated with endophthalmitis and retinal detachments and, more rarely, retinal vasculitis with or without occlusion. Proper aseptic injection technique must always be used when administering EYLEA HD or EYLEA. Patients and/or caregivers should be instructed to report any signs and/or symptoms suggestive of endophthalmitis, retinal detachment, or retinal vasculitis without delay and should be managed appropriately.`,
      },
      {
        text: `Acute increases in intraocular pressure have been seen within 60 minutes of intravitreal injection, including with EYLEA HD and EYLEA. Sustained increases in intraocular pressure have also been reported after repeated intravitreal dosing with VEGF inhibitors. Intraocular pressure and the perfusion of the optic nerve head should be monitored and managed appropriately.`,
      },
      {
        text: `There is a potential risk of arterial thromboembolic events (ATEs) 
               following intravitreal use of VEGF inhibitors, including EYLEA HD and EYLEA. 
               ATEs are defined as nonfatal stroke, nonfatal myocardial 
               infarction, or vascular death (including deaths of unknown cause).`,
        bullets: [
          {
            text: `EYLEA HD: The incidence of reported thromboembolic events in
                   the wet AMD study (PULSAR) from baseline through week 48 was 
                   0.4% (3 out of 673) in the combined group of patients treated 
                   with EYLEA HD compared with 1.5% (5 out of 336) in patients 
                   treated with EYLEA 2 mg. The incidence in the DME study (PHOTON)
                   from baseline to week 48 was 3.1% (15 out of 491) in the combined 
                   group of patients treated with EYLEA HD compared with 3.6% 
                   (6 out of 167) in patients treated with EYLEA 2 mg.`,
          },
          {
            text: `EYLEA: The incidence of reported thromboembolic events in wet AMD studies during the first year was 1.8% (32 out of 1824) in the combined group of patients treated with EYLEA compared with 1.5% (9 out of 595) in patients treated with ranibizumab; through 96 weeks, the incidence was 3.3% (60 out of 1824) in the EYLEA group compared with 3.2% (19 out of 595) in the ranibizumab group. The incidence in the DME studies from baseline to week 52 was 3.3% (19 out of 578) in the combined group of patients treated with EYLEA compared with 2.8% (8 out of 287) in the control group; from baseline to week 100, the incidence was 6.4% (37 out of 578) in the combined group of patients treated with EYLEA compared with 4.2% (12 out of 287) in the control group. There were no reported thromboembolic events in the patients treated with EYLEA in the first six months of the RVO studies.`,
          },
        ],
      },
    ],
  },
  {
    title: 'Adverse Reactions',
    bullets: [
      {
        text: `EYLEA HD:`,
        bullets: [
          {
            text: `The most common adverse reactions (≥3%) reported in patients receiving EYLEA HD were cataract, conjunctival hemorrhage, intraocular pressure increased, ocular discomfort/eye pain/eye irritation, vision blurred, vitreous floaters, vitreous detachment, corneal epithelium defect, and retinal hemorrhage.`,
          },
        ],
      },
      {
        text: `EYLEA:`,
        bullets: [
          {
            text: `Serious adverse reactions related to the injection procedure have occurred in <0.1% of intravitreal injections with EYLEA including endophthalmitis and retinal detachment.`,
          },
          {
            text: `The most common adverse reactions (≥5%) reported in patients receiving EYLEA were conjunctival hemorrhage, eye pain, cataract, vitreous detachment, vitreous floaters, and intraocular pressure increased.`,
          },
        ],
      },
      {
        text: `Patients may experience temporary visual disturbances after an intravitreal injection with EYLEA HD or EYLEA and the associated eye examinations. Advise patients not to drive or use machinery until visual function has recovered sufficiently.`,
      },
    ],
  },
];

export const COPY = [
  {
    title: 'Indications',
    eyleaHD: `EYLEA® HD (aflibercept) Injection 8 mg is indicated for the treatment of patients with Neovascular (Wet) Age-Related Macular Degeneration (AMD), Diabetic Macular Edema (DME), and Diabetic Retinopathy (DR).`,
    eylea: `EYLEA® (aflibercept) Injection 2 mg is indicated for the treatment of patients with Neovascular (Wet) Age-Related Macular Degeneration (AMD), Macular Edema following Retinal Vein Occlusion (RVO), Diabetic Macular Edema (DME), and Diabetic Retinopathy (DR).`,
  },
];
