import { css } from 'styled-components';

import * as colors from './colors';

export const appGutters = '120px';
export const boxShadowStandard = '0px 3px 5px 0px rgba(0, 0, 0, 0.25)';
export const headerHeight = '120px';
export const footerHeight = '155px';
export const pageHorizontalPadding = '20px';

export const backgroundStyles = css`
  background-color: ${colors.darkBlue_10};
`;
