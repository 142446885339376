import { useEffect, useState } from 'react';

import { createStateArrayUtils } from '../helpers';

export const NEW_ROW_ID = 'NEW_ROW';

/**
 * @param {object} config
 * @param {object} config.apiMethods
 * @param {Function} config.apiMethods.create
 * @param {Function} config.apiMethods.read
 * @param {Function} config.apiMethods.update
 * @param {Function} config.apiMethods.delete
 * @param {Function} config.apiMethods.subscribe
 * @param {String} config.idProp
 * @param {Boolean} config.isVisible
 * @param {Function} config.mapFetchedData
 * @param {Function} config.onCreateSuccess
 */
const useRowForm = ({
  apiMethods,
  idProp = 'id',
  isVisible = true,
  mapFetchedData,
  onCreateSuccess,
}) => {
  const [editModeRows, setEditModeRows] = useState([]);
  const [pendingChanges, setPendingChanges] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    id: null,
    isOpen: false,
  });

  const editModeRowsUtils = createStateArrayUtils(
    setEditModeRows,
    editModeRows
  );
  const pendingChangesUtils = createStateArrayUtils(
    setPendingChanges,
    pendingChanges
  );

  useEffect(() => {
    if (!apiMethods.subscribe) return;
    const unsubscribe = apiMethods.subscribe((data) => {
      setData(data);
    });
    // return unsubscribe fn so that we unsubscribe when the
    // component that calls this hook is destroyed
    return () => unsubscribe();
    // no dependency array to ensure we only subscribe once
  }, []);

  useEffect(() => {
    if (pendingChanges.length || !isVisible || !apiMethods.read) return;
    if (!data.length) setIsLoading(true);
    apiMethods.read().then((result = []) => {
      if (mapFetchedData) {
        setData(mapFetchedData(result));
      } else {
        setData(result);
      }
      setIsLoading(false);
    });
  }, [isVisible, pendingChanges]);

  const handleCreate = async (item) => {
    pendingChangesUtils.add(NEW_ROW_ID);
    await apiMethods.create(item);
    pendingChangesUtils.remove(NEW_ROW_ID);
    if (onCreateSuccess) onCreateSuccess();
  };
  const activateEditMode = (item) => {
    editModeRowsUtils.add(item[idProp]);
  };
  const deactivateEditMode = (item) => {
    editModeRowsUtils.remove(item[idProp]);
  };
  const handleUpdate = async (item) => {
    pendingChangesUtils.add(item[idProp]);
    await apiMethods.update(item);
    pendingChangesUtils.remove(item[idProp]);
    editModeRowsUtils.remove(item[idProp]);
  };
  const showDeleteConfirmation = (item) => {
    setDeleteConfirmation({ id: item[idProp], isOpen: true });
  };
  const clearDeleteConfirmation = () => {
    setDeleteConfirmation({ id: null, isOpen: false });
  };
  const handleDelete = async (item) => {
    const id = item ? item[idProp] : deleteConfirmation.id;
    pendingChangesUtils.add(id);
    editModeRowsUtils.remove(id);
    await apiMethods.delete(id);
    clearDeleteConfirmation();
    pendingChangesUtils.remove(id);
  };

  return {
    activateEditMode,
    deactivateEditMode,
    editModeRows,
    data,
    handleCreate,
    handleUpdate,
    handleDelete,
    isLoading,
    pendingChanges,
    showDeleteConfirmation,
    clearDeleteConfirmation,
    deleteConfirmation,
  };
};

export default useRowForm;
