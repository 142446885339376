import { padding, stripUnit, transitions } from 'polished';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { linkStyles } from './Link';
import * as colors from '../styles/colors';
import * as typography from '../styles/typography';

export const LIST_PADDING_X = '20px';

export const ListTextButton = styled(Link)`
  ${linkStyles};
  ${typography.bold};
  color: ${colors.greyscale[400]};
  text-transform: uppercase;
`;

export const ListTitleBar = styled.div`
  align-items: baseline;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  ${padding('8px', LIST_PADDING_X)}
`;

export const ListTitleHeader = styled.div`
  ${typography.h4};
  ${typography.bold};
`;

export const IconColumn = styled.div`
  flex-grow: 0;
  width: 80px;
  text-align: center;
  margin: 0 4px;
`;
export const DataColumn = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${(props) => props.justify};
  flex-grow: 1;
  width: ${(props) => props.width};
  margin: 0 4px;
`;
export const TitleColumn = styled(DataColumn)`
  ${typography.bold};
  ${typography.h5};
`;
// adjust this to make things more responsive?
export const ListRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  max-width: 100%;
`;
export const ListHeaderRow = styled(ListRow)`
  align-items: flex-end;
  padding: 8px 0;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: ${colors.white};
  & ${IconColumn} {
    ${typography.caption};
    text-transform: uppercase;
  }
  & ${DataColumn}, & ${TitleColumn} {
    ${linkStyles};
    ${typography.body};
    ${typography.bold};
    color: ${colors.darkBlue};
    font-weight: bold;
    overflow: hidden;
  }
`;
export const SortIcon = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  width: fit-content;
  & svg {
    transform: rotate(
      ${(props) => (props.order === 'asc' ? '180deg' : '0deg')}
    );
    & * {
      fill: ${({ theme }) => theme.foreground};
      stroke: ${({ theme }) => theme.foreground};
    }
  }
  & + * {
    margin-left: 4px;
  }
`;

export const ListBodyRow = styled(ListRow)`
  ${padding('16px', LIST_PADDING_X)}
  ${typography.body};
  display: block;
  background: ${({ theme }) => theme.tableRow};
  color: ${({ theme }) => theme.text};
  ${transitions(['color', 'background-color'], '0.15s linear')}
  flex-grow: 1
  overflow-y: auto;
`;

export const ListBodyRowContent = styled.div`
  display: flex;
  /*
    negative margin fixes alignment from ListBodyRow padding.
    This aligns things correctly if we just have text in a row
    (no results view) vs. the regular cell alignment
  */
  margin-left: ${stripUnit(LIST_PADDING_X) * -1}px;
  margin-right: ${stripUnit(LIST_PADDING_X) * -1}px;
  & + & {
    margin-top: 8px;
  }
`;

export const List = styled.div`
  & > * {
    margin: 6px 0;
  }
  height: 100%;
  min-height: 100%;
`;

export const IconWrapper = styled.div`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
