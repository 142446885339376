import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { padding, size, stripUnit } from 'polished';

import { boxShadowStandard } from '../styles/constants';
import * as typography from '../styles/typography';
import { linkStyles } from './Link';

const TooltipWrapper = styled.div`
  ${padding('12px', '24px')}
  ${(props) => size(props.height, props.width)};
  background-color: ${({ theme }) => theme.background};
  box-shadow: ${boxShadowStandard};
  position: fixed;
  border: 1px solid ${({ theme }) => theme.foreground};
  top: ${(props) => props.top}px;
  /* subtract padding to maintain alignment */
  left: ${(props) => props.left - 48}px;
  z-index: 500;
`;

const CloseIcon = styled.div`
  ${linkStyles};
  ${typography.bold};
  cursor: pointer;
  color: ${({ theme }) => theme.foreground};
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const POSITIONS_X = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};
export const POSITIONS_Y = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
};

const calculatePosition = ({ anchorEl, offset, position, height, width }) => {
  const result = { left: 0, top: 0 };
  if (position.x === POSITIONS_X.LEFT) {
    result.left = anchorEl.offsetLeft - width;
  }
  if (position.x === POSITIONS_X.CENTER) {
    result.left = anchorEl.offsetLeft + anchorEl.clientWidth / 2 - width / 2;
  }
  if (position.x === POSITIONS_X.RIGHT) {
    result.left = anchorEl.offsetLeft + anchorEl.clientWidth;
  }

  if (position.y === POSITIONS_Y.BOTTOM) {
    result.top = anchorEl.offsetTop + anchorEl.clientHeight;
  }
  if (position.y === POSITIONS_Y.CENTER) {
    result.top = anchorEl.offsetTop + anchorEl.clientHeight / 2 - height / 2;
  }
  if (position.y === POSITIONS_Y.TOP) {
    result.top = anchorEl.offsetTop - height;
  }
  return {
    left: result.left + offset.x,
    top: result.top + offset.y,
  };
};

const Tooltip = ({
  anchorEl,
  children,
  className,
  height,
  isOpen,
  offset,
  onClose,
  position,
  width,
}) => {
  const tooltipRef = useRef(null);

  if (!isOpen) return null;

  const absolutePosition = calculatePosition({
    anchorEl: anchorEl.current ?? {},
    offset,
    position,
    height: stripUnit(height),
    width: stripUnit(width),
  });

  return (
    <TooltipWrapper
      className={className}
      ref={tooltipRef}
      height={height}
      width={width}
      {...absolutePosition}
    >
      <CloseIcon onClick={onClose}>X</CloseIcon>
      {children}
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  anchorEl: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  offset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  position: PropTypes.shape({
    x: PropTypes.oneOf(['left', 'center', 'right']),
    y: PropTypes.oneOf(['top', 'center', 'bottom']),
  }),
  width: PropTypes.string,
};

Tooltip.defaultProps = {
  offset: {
    x: 0,
    y: 0,
  },
  position: {
    x: 'center',
    y: 'bottom',
  },
};

export default Tooltip;
