import { size } from 'polished';
import React, { useState } from 'react';
import { isIE } from 'react-device-detect';
import styled from 'styled-components';

import { InternetExplorerLogo } from './Icons';
import Modal from './Modal';
import * as typography from '../styles/typography';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LogoWrapper = styled.div`
  & > svg {
    ${size(96)}
  }
`;
const Heading = styled.h2`
  ${typography.bold};
  color: ${({ theme }) => theme.foreground};
`;

const BrowserSupportModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      showCloseIcon
    >
      <ContentWrapper>
        <LogoWrapper>{InternetExplorerLogo}</LogoWrapper>
        <Heading>Internet Explorer is not supported.</Heading>
        <p>Please use another browser for optimal user experience.</p>
      </ContentWrapper>
    </Modal>
  );
};

// this is separate so that we never even have to run the
// useState hook from the actual modal if they're not using IE
const BrowserSupportModalWrapper = () => {
  if (!isIE) return null;
  return <BrowserSupportModal />;
};

export default BrowserSupportModalWrapper;
