import {
  INDICATION_OPTIONS,
  CHANNEL_OPTIONS,
  MEDICATION_OPTIONS,
  EYLEA_ID,
  EYLEA_HD_ID,
} from '../constants';
import { SUPER_USER } from '../constants/roles';

export const getProductById = (productId) => {
  if (productId) {
    const option = MEDICATION_OPTIONS.find(({ value }) => value === productId);
    return option ?? {};
  }
  return {};
};

export const getChannelLabelById = (channelId) => {
  if (channelId) {
    const option = CHANNEL_OPTIONS.find(
      ({ label, value }) => value === channelId
    );
    return option ? option.label : '';
  }
  return '';
};

export const getIndicationLabelById = (indicationId) => {
  const option = INDICATION_OPTIONS.find(
    ({ label, value }) => value === indicationId
  );
  return option.label;
};

const getIndicationsFieldName = (productId) => {
  if (productId === EYLEA_ID) {
    return 'indications';
  } else if (productId === EYLEA_HD_ID) {
    return 'indicationsHD';
  }
};

const getIndication = (policy, indicationId, productId) => {
  const indicationsFieldName = getIndicationsFieldName(productId);
  return policy[indicationsFieldName].find(
    (i) => i.indicationId === indicationId
  );
};

export const decoratePolicyWithIndication = (
  policy,
  indicationId,
  productId
) => ({
  ...policy,
  ...getIndication(policy, indicationId || policy.indicationId, productId),
});

export const getCoverageTitle = (role) =>
  `${role === SUPER_USER ? 'Find' : 'View'} Insurance Coverage`;

const addToStateArray = (setter, arr) => (val) => {
  setter([...arr, val]);
};
const removeFromStateArray = (setter, arr) => (val) => {
  setter(arr.filter((i) => i !== val));
};
const toggleStateArray = (setter, arr) => (val) => {
  if (arr.includes(val)) {
    removeFromStateArray(setter, arr)(val);
  } else {
    addToStateArray(setter, arr)(val);
  }
};

export const createStateArrayUtils = (setter, arr) => ({
  add: addToStateArray(setter, arr),
  toggle: toggleStateArray(setter, arr),
  remove: removeFromStateArray(setter, arr),
});

export const sortObjectsExcludingUndefined = ({ items, sortProp, sortFn }) => {
  const itemsWithDefinedProp = items.filter(
    (i) => typeof i[sortProp] !== 'undefined'
  );
  const itemsWithUndefinedProp = items.filter(
    (i) => typeof i[sortProp] === 'undefined'
  );
  const sortedItems = itemsWithDefinedProp.sort(sortFn);
  const combinedItems = [...sortedItems, ...itemsWithUndefinedProp];
  return combinedItems;
};

/**
 * Trims search input
 * @param {String} val String value to trim
 * @param {Object} config
 * @param {Boolean} config.stripPunctuation should it also remove punctuation from the input?
 */
export const trimSearchInput = (val, { stripPunctuation = false } = {}) => {
  let res = val;
  if (stripPunctuation) res = res.replace(/[^\w ]/g, '');
  return res.trim();
};

export const getProductLabelById = (productId) =>
  productId === EYLEA_HD_ID ? 'Eylea HD' : 'Eylea';
