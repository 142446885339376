import { padding, rgba } from 'polished';
import styled from 'styled-components';

import Button from '../Button';
import Input from '../Input';
import PageWrapper from '../PageWrapper';
import * as colors from '../../styles/colors';
import { pageHorizontalPadding } from '../../styles/constants';
import * as typography from '../../styles/typography';

const innerGutter = 20;

export const StyledWrapper = styled(PageWrapper)`
  ${padding(32, pageHorizontalPadding, 0)};
  align-items: center;
  & > * {
    margin: 8px 0;
  }
`;
const JumboBox = styled.div`
  border-radius: 16px;
  padding: 16px ${innerGutter}px;
  width: calc(100% - ${innerGutter * 2}px);
  & > * {
    margin-bottom: 8px;
    margin-top: 8px;
  }
`;
export const SelectionContainer = styled.div`
  width: 100%;
  min-height: 150px;
  display: flex;
`;
export const ProductContainer = styled.div`
  width: 280px;
`;
export const SectionLabel = styled.div`
  ${typography.bold};
  ${typography.h6};
`;
export const IndicationBox = styled.div`
  margin-left: 80px;
`;
export const RadioSet = styled.div`
  display: flex;
  margin-top: 14px;
  margin-left: -6px;
  margin-right: -6px;
  & > * {
    margin-left: 6px;
    margin-right: 6px;
  }
`;
export const FilterControls = styled(JumboBox)`
  background: ${rgba(colors.greyscale[600], 0.07)};
  color: ${colors.greyscale[400]};
`;
export const LetterFilter = styled.span`
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
  ${(props) => (props.enabled ? typography.bold : typography.regular)};
`;
export const LetterFilterSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px -6px;
  & ${LetterFilter} {
    margin: 0 6px;
  }
`;
// border needs !important because it can collide with default styling
export const SearchBox = styled(Input)`
  border: 3px solid ${colors.greyscale[300]} !important;
  height: 30px;
  padding: 4px 8px;
`;
export const AllResultsContainer = styled.div`
  overflow: auto;
  width: 40%;
`;
export const SelectedResultsContainer = styled.div`
  background-color: ${rgba(colors.greyscale[200], 0.33)};
  overflow: auto;
  width: 60%;
  & > div {
    background-color: ${rgba(colors.white, 0.5)};
  }
`;
export const ResultsWrapper = styled.div`
  display: flex;
  max-height: 300px;
  min-height: 300px;
  padding: 0 ${innerGutter}px;
  width: calc(100% - ${innerGutter * 2}px);
  & > div {
    padding: 4px 12px;
  }
`;
export const SubmitButton = styled(Button)`
  margin-left: auto;
  margin-right: ${innerGutter}px;
  padding-bottom: 10px;
  padding-top: 10px;
  &[disabled] {
    background-color: ${rgba(colors.darkBlue, 0.2)};
  }
`;

export const ResultLink = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export const ResultLinksContainer = styled.div`
  margin-bottom: 100px;
  width: 100%;
  & ${ResultLink} {
    margin: 12px 0;
  }
`;
export const ErrorText = styled.div`
  color: ${({ theme }) => theme.error};
`;
