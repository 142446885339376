import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { useFirebaseContext } from './FirebaseProvider';
import { useUserApi } from '../api/user';
import { logToSentry } from '../services/errorLogging';

const DEFAULT_STATE = {
  error: null,
  loading: true,
  user: null,
  clearAuthError: () => {},
  refetchUser: () => new Promise(),
  sendPasswordResetEmail: (email) => new Promise(),
  signIn: (email, password, staySignedIn) => new Promise(),
  signOut: () => new Promise(),
};

export const AuthContext = React.createContext(DEFAULT_STATE);

export const useAuthContext = () => useContext(AuthContext);

const mapUserObject = (fullUser) => ({
  displayName: fullUser.displayName,
  email: fullUser.email,
  policiesShownOnHomepage: fullUser.policiesShownOnHomepage || [],
  practiceId: fullUser.practiceId,
  role: fullUser.role,
  uid: fullUser.uid,
});

const AuthProvider = ({ children }) => {
  const firebase = useFirebaseContext();
  const { GetExtendedUser } = useUserApi();
  const [authUser, setAuthUser] = useState(firebase.auth().currentUser);
  const [error, setError] = useState(null);
  // this is really only for the initial, global loading state
  // use local state in components if they need something more specific
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const getPersistence = (staySignedIn) => {
    return staySignedIn
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;
  };

  const refetchUser = async () => {
    const userDoc = await GetExtendedUser(user.uid);
    setUser({ ...user, ...userDoc });
  };

  // on login, fetch extended user object for setUser
  // this should be simplified / cleaned up
  useEffect(() => {
    const removeListener = firebase
      .auth()
      .onAuthStateChanged(async (userDoc) => {
        setLoading(true);
        setAuthUser(userDoc);
        if (userDoc) {
          const extendedUser = await GetExtendedUser(userDoc.uid);
          const mappedUser = mapUserObject({ ...userDoc, ...extendedUser });
          setUser(mappedUser);
        } else {
          setUser(null);
        }
        setLoading(false);
      });
    return () => removeListener();
  }, [authUser, firebase]);

  const signIn = async (email, password, staySignedIn) => {
    try {
      setError(null);
      await firebase.auth().setPersistence(getPersistence(staySignedIn));
      return await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      setError(err);
      const user = { username: email };
      const extra = {
        code: err.code,
        payload: err.payload,
      };
      const section = 'login';
      const tags = { type: 'frontend' };
      logToSentry(err, user, extra, section, tags);
    } finally {
      setLoading(false);
    }
  };

  const signOut = () => {
    setError(null);
    setUser(null);
    return firebase.auth().signOut();
  };

  const sendPasswordResetEmail = async (email) => {
    return await firebase.auth().sendPasswordResetEmail(email);
  };

  return (
    <AuthContext.Provider
      value={{
        ...DEFAULT_STATE,
        error,
        loading,
        user,
        clearAuthError: () => setError(null),
        refetchUser,
        sendPasswordResetEmail,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
