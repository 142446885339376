import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import {
  ErrorText,
  ForgotLink,
  HelpLink,
  LoginBox,
  LoginButton,
  LoginInput,
  LoginLabel,
} from './LoginForm.components';
import Checkbox, { labelPositions } from '../../components/Checkbox';
import { email as emailRegex } from '../../constants/regex';
import { useAuthContext } from '../../providers/AuthProvider';
import { darkTheme } from '../../styles/themes';

const LoginForm = () => {
  const history = useHistory();
  const { clearAuthError, error, signIn } = useAuthContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberUser, setRememberUser] = useState(false);

  const isFormValid = emailRegex.test(email) && !!password;

  const updateField = (setter) => (event) => {
    setter(event.target.value);
    // reset error on user input
    clearAuthError();
  };

  const handleForgotPassword = () => {
    history.push('/login/forgot');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    await signIn(email, password, rememberUser);
    // the route guards automatically redirect us to
    // the home page once the user is logged in
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {error && <ErrorText>Login Error</ErrorText>}
      <LoginBox onSubmit={handleSubmit}>
        <LoginLabel>LOGIN</LoginLabel>
        <Checkbox
          label="Stay logged in"
          labelPosition={labelPositions.LEFT}
          onChange={() => setRememberUser(!rememberUser)}
          value={rememberUser}
        />
        <LoginInput
          dark
          error={!!error}
          inputProps={{
            onChange: updateField(setEmail),
            placeholder: 'Email Address',
            type: 'text',
            value: email,
          }}
        />
        <LoginInput
          dark
          error={!!error}
          inputProps={{
            onChange: updateField(setPassword),
            placeholder: 'Password',
            type: 'password',
            value: password,
          }}
        />
        <ForgotLink onClick={handleForgotPassword}>Forgot Password?</ForgotLink>
        <LoginButton disabled={!isFormValid}>Submit</LoginButton>
        <HelpLink>
          Need more help? Contact your Reimbursement Business Manager.
        </HelpLink>
      </LoginBox>
    </ThemeProvider>
  );
};

export default LoginForm;
