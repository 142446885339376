import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import JumboButton from '../components/JumboButton';
import routes from '../constants/routes';
import * as colors from '../styles/colors';
import { appGutters } from '../styles/constants';
import * as typography from '../styles/typography';
import { darkTheme } from '../styles/themes';
import eyleaLogo from '../assets/images/logo_2mg.png';
import eyleaHdLogo from '../assets/images/logo_8mg.png';

const NotFoundWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 60px ${appGutters};
  width: 100%;
  box-sizing: border-box;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  margin: 0 35px;
`;

const Header = styled.h1`
  ${typography.h1};
  ${typography.bold};
  color: ${colors.darkBlue};
`;

const ErrorText = styled.h3`
  ${typography.h5};
  ${typography.bold};
  margin: 5px 0 35px;
  color: ${colors.darkBlue};
`;

const NotFound = () => {
  const history = useHistory();
  const goHome = () => {
    history.push(routes.home);
  };

  return (
    <NotFoundWrapper>
      <ImageWrapper>
        <Logo alt="EYLEA 8mg logo" src={eyleaHdLogo} />
        <Logo alt="EYLEA 2mg logo" src={eyleaLogo} />
      </ImageWrapper>
      <Header>404 Error</Header>
      <ErrorText>Sorry we cannot find the page you are looking for.</ErrorText>
      <ThemeProvider theme={darkTheme}>
        <JumboButton onClick={goHome}>Return to Homepage</JumboButton>
      </ThemeProvider>
    </NotFoundWrapper>
  );
};

export default NotFound;
