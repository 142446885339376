import styled from 'styled-components';

import { linkStyles } from './Link';
import * as typography from '../styles/typography';

const TextButton = styled.span`
  ${typography.bold};
  ${linkStyles};
  text-transform: uppercase;
`;

export default TextButton;
