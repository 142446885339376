import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import { linkStyles } from './Link';
import * as colors from '../styles/colors';
import * as typography from '../styles/typography';

ReactModal.setAppElement('#root');

const modalStyles = {
  content: {
    // general orientation / layout
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    // adjust these based on breakpoints later for tablet/mobile
    maxWidth: '40%',
    minWidth: '40%',
    transform: 'translate(-50%, -50%)',
    // actual styling
    borderRadius: '12px',
    border: `4px solid ${colors.greyscale[100]}`,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
  },
};

const TopIconRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CloseIconWrapper = styled.div`
  ${linkStyles};
  ${typography.bold};
  // don't use rem since this is an icon, not text
  font-size: 20px;
  cursor: pointer;
`;

// we need to pass the className as portalClassName
// to style this component using styled-components
/**
 * Styled wrapper for the react-modal component.
 * @param {Props} props
 */
const Modal = ({
  children,
  className,
  onRequestClose,
  showCloseIcon,
  style,
  ...props
}) => {
  const mergedStyles = {
    ...modalStyles,
    content: {
      ...modalStyles.content,
      ...style,
    },
  };

  return (
    <ReactModal
      onRequestClose={onRequestClose}
      portalClassName={className}
      style={mergedStyles}
      {...props}
    >
      {showCloseIcon && (
        <TopIconRow>
          <CloseIconWrapper onClick={onRequestClose}>X</CloseIconWrapper>
        </TopIconRow>
      )}
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeIcon: PropTypes.node,
  onRequestClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  style: PropTypes.object,
};

export default Modal;
