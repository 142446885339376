export const tableLeftSpacing = '60px';

export const columnWidths = {
  displayName: '18%',
  address1: '16%',
  address2: '11%',
  city: '8%',
  usState: '5%',
  zip: '6%',
  phone: '9%',
  editButtons: '10%',
  expandButton: '17%',
};

export const userColumnWidths = {
  displayName: '20%',
  email: '45%',
  editButtons: '15%',
  passwordReset: '20%',
};

export const EMPTY_PRACTICE = {
  displayName: '',
  address1: '',
  address2: '',
  city: '',
  usState: '',
  zip: '',
  phone: '',
};

export const EMPTY_USER = {
  displayName: '',
  email: '',
};
