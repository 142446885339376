import { useFirebaseContext } from '../providers/FirebaseProvider';

export const useUserApi = () => {
  const firebase = useFirebaseContext();

  const GetExtendedUser = async (userId) => {
    const userDoc = await firebase.firestore
      .collection('users')
      .doc(userId)
      .get();
    return userDoc.data();
  };

  const CreateUser = async (role, user, practiceId) => {
    const defaultPassword = 'Password1';
    const userObj = {
      email: user.email,
      displayName: user.displayName,
      password: defaultPassword,
      role,
      practiceId,
    };

    const createUser = firebase.functions.httpsCallable(
      'createCallableUserInAuth'
    );

    const { data } = await createUser(userObj);
    // comes back with string if successful, error object if not
    if (typeof data === 'string') {
      await firebase.auth().sendPasswordResetEmail(user.email);
    } else if (data.errorInfo) {
      const err = new Error(data.errorInfo.message);
      err.code = data.errorInfo.code;
      throw err;
    }
  };

  const GetUsersFromIds = async (userIds) => {
    const userDataRequests = userIds.map((id) => {
      return firebase.firestore.collection('users').doc(id).get();
    });
    const userDataResults = await Promise.all(userDataRequests);
    const userData = userDataResults.map((data, index) => {
      return {
        ...data.data(),
        id: userIds[index],
      };
    });
    return userData;
  };

  const GetUsersFromPractice = async (practiceId) => {
    try {
      const getUsers = await firebase.firestore
        .collection('practices')
        .doc(practiceId)
        .get();
      const practiceUserData = getUsers.data();
      if (practiceUserData?.users) {
        const userIds = practiceUserData.users.map((user) => user.userId);
        const userData = await GetUsersFromIds(userIds);
        return userData;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  };
  const EditUser = async (user) => {
    try {
      const userObj = {
        email: user.email,
        displayName: user.displayName,
        userId: user.id,
      };
      const editUser = firebase.functions.httpsCallable('editUserInAuth');
      await editUser(userObj)
        .then((result) => result.data)
        .then(({ displayName, email, uid }) =>
          firebase.firestore.collection('users').doc(uid).set(
            {
              email,
              displayName,
            },
            { merge: true }
          )
        );
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteUser = async (userId, practiceId) => {
    try {
      const deleteUser = firebase.functions.httpsCallable('deleteUserInAuth');
      return await deleteUser(userId)
        .then(() => {
          return firebase.firestore.collection('users').doc(userId).delete();
        })
        .then(async () => {
          const getPractice = firebase.firestore
            .collection('practices')
            .doc(practiceId);
          const rawPracticeData = await getPractice.get();
          const practiceData = rawPracticeData.data();
          const newUsers = practiceData.users
            ? practiceData.users.filter((user) => user.userId !== userId)
            : [];
          return firebase.firestore
            .collection('practices')
            .doc(practiceId)
            .set(
              {
                users: [...newUsers],
              },
              { merge: true }
            );
        });
    } catch (error) {
      console.log(error);
    }
  };
  const ListenToUserUpdates = (userId, onChange) => {
    const unsubscribe = firebase.firestore
      .collection('users')
      .doc(userId)
      .onSnapshot((doc) => {
        const data = doc.data();
        onChange(data);
      });
    return unsubscribe;
  };
  return {
    CreateUser,
    EditUser,
    DeleteUser,
    GetExtendedUser,
    GetUsersFromIds,
    GetUsersFromPractice,
    ListenToUserUpdates,
  };
};
