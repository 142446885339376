import { useEffect, useState } from 'react';

import { sortObjectsExcludingUndefined } from '../helpers';
import { useAuthContext } from '../providers/AuthProvider';
import { useFirebaseContext } from '../providers/FirebaseProvider';

const usePolicies = () => {
  const { user } = useAuthContext();
  const firebase = useFirebaseContext();

  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user?.uid) return;
    const fetchPolicies = async () => {
      setLoading(true);
      const currentPolicyNameDoc = await firebase.firestore
        .collection('currentCollections')
        .get();
      const currentPolicyData = currentPolicyNameDoc.docs.map((d) => d.data());
      const currentPolicyName = currentPolicyData.length
        ? currentPolicyData[1].currentCollectionName
        : null;

      const policiesDoc = await firebase.firestore
        .collection('mmitPoliciesCurrent')
        .doc(currentPolicyName)
        .collection('currentPolicies')
        .get();
      const allPolicies = policiesDoc.docs.map((d) => d.data());
      console.log(allPolicies.length);
      const sortedPolicies = sortObjectsExcludingUndefined({
        items: allPolicies,
        sortProp: 'lives',
        sortFn: (a, b) => b.lives - a.lives,
      });
      setPolicies(sortedPolicies);
      setLoading(false);
    };
    fetchPolicies();
  }, [firebase, user?.uid]);

  return {
    policies,
    loading,
  };
};

export default usePolicies;
