const getFilteredData = (data, { letterFilter, textFilter }) => {
  let filteredData = data;

  if (!!letterFilter) {
    filteredData = filteredData.filter(
      (i) => i.name[0].toUpperCase() === letterFilter
    );
  }

  if (!!textFilter) {
    filteredData = filteredData.filter((i) =>
      i.name.toUpperCase().includes(textFilter.toUpperCase())
    );
  }
  // this assumes that we are sorting by a property called 'name'
  // be aware of this in case the model for policies or plans ever changes
  filteredData = filteredData.sort((a, b) => a.name.localeCompare(b.name));
  return filteredData;
};

export default getFilteredData;
