import React from 'react';
import PropTypes from 'prop-types';

import { IconLink } from './AppHeader.components';
import * as Icons from '../Icons';
import * as roles from '../../constants/roles';
import { useAppContext } from '../../providers/AppProvider';
import { useAuthContext } from '../../providers/AuthProvider';

const AssistanceLink = ({ superUsers }) => {
  const { user } = useAuthContext();
  const { practice } = useAppContext();

  if (!user || user.role === roles.RBM || !practice) return null;

  return (
    <>
      NEED ASSISTANCE?&nbsp; Click here to email your RBM
      <IconLink href={`mailto:${practice?.rbm?.email}`}>
        {Icons.EmailSupport}
      </IconLink>
    </>
  );
};

AssistanceLink.propTypes = {
  superUsers: PropTypes.array,
};

export default AssistanceLink;
