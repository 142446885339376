import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { linkStyles } from '../../components/Link';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';
import {
  appGutters,
  boxShadowStandard,
  headerHeight,
} from '../../styles/constants';

export const AppHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-shadow: ${boxShadowStandard};
  display: flex;
  z-index: 10;
  // may need to reconsider this because of browser support for sticky
  position: sticky;
  top: 0;
  height: ${headerHeight};
  padding: 0 ${appGutters};
`;
export const ToolbarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 26px 12px 10px;
`;
export const IconWrapper = styled.div`
  ${linkStyles};
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
`;
export const UsernameWrapper = styled.div`
  align-items: center;
  display: flex;
  & > * {
    margin: 0 8px;
  }
`;

export const UserBar = styled.div`
  align-items: center;
  color: ${colors.greyscale[500]};
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 2px 0;
  & > * {
    margin: 0 16px;
  }
`;

export const PiSelect = styled.div`
  width: 175px;
`;

export const NavBar = styled.nav`
  align-items: flex-end;
  background-color: ${({ theme }) => theme.background};
  border-bottom: 6px solid ${({ theme }) => theme.highlight};
  color: ${({ theme }) => theme.foreground};
  display: flex;
  flex-grow: 1;
  font-size: 0.75rem;
  justify-content: flex-end;
  padding-bottom: 12px;
  & > * {
    margin: 0 20px;
  }
`;

export const StyledLink = styled(NavLink)`
  ${linkStyles};
  &:hover,
  &:active,
  &.active {
    color: ${({ activeColor, disabled, theme }) =>
      disabled ? theme.disabled : activeColor || theme.foreground};
    ${typography.bold};
  }

  // To prevent text from shifting when bold
  &::before {
    display: block;
    content: '${(props) => props.children}';
    height: 0;
    visibility: hidden;
    ${typography.bold};
  }
`;

export const IconLink = styled.a`
  ${linkStyles};
`;

export const SignOutLink = styled.div`
  ${linkStyles};
  ${typography.bold};
  color: ${({ theme }) => theme.text};
  cursor: pointer;
`;
