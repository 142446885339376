import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Link from './Link';
import Modal from './Modal';
import * as typography from '../styles/typography';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  color: ${({ theme }) => theme.foreground};
  & > * {
    margin: 8px 0;
  }
`;
const Heading = styled.div`
  ${typography.h4};
`;
const SubHeading = styled.div`
  ${typography.h5};
`;

const StyledButton = styled(Button)`
  border-radius: 0;
`;
const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const modalStyles = {
  borderRadius: '0px',
};

const ConfirmDeleteModal = ({
  isOpen,
  onCancel,
  onContinue,
  deleteTargetName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      showCloseIcon
      style={modalStyles}
    >
      <ContentWrapper>
        <Heading>You are deleting a {deleteTargetName}.</Heading>
        <SubHeading>This action cannot be undone.</SubHeading>
        <StyledButton onClick={onContinue}>Continue</StyledButton>
        <StyledLink onClick={onCancel}>GO BACK</StyledLink>
      </ContentWrapper>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  deleteTargetName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
};

export default ConfirmDeleteModal;
