import React from 'react';
import styled from 'styled-components';

import Link from '../components/Link';
import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';

import { bold } from '../styles/typography';

const TextHeading = styled.h4`
  ${bold}
  margin-bottom: 0;
`;

const Text = styled.p`
  margin: 0 0 1rem 0;
`;
// distinct from page wrapper, which adds the banners,
// this component wraps the text content of the page
const ContentWrapper = styled.div`
  width: 100%;
`;
const LinkBold = styled(Link)`
  ${bold}
  text-decoration: underline;
`;

const Resources = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeading>Resources</PageHeading>

        <Text>
          From ensuring your eligible patients have access to EYLEA HD and
          EYLEA, to helping them navigate reimbursement, EYLEA4U&reg; is here to
          help.
        </Text>

        <TextHeading>What is EYLEA4U?</TextHeading>
        <Text>
          EYLEA4U is a support program that offers patient support,
          reimbursement assistance, and access solutions.
        </Text>

        <Text>
          Our dedicated team and wide range of resources can help patients
          access EYLEA HD and EYLEA and answer any questions you might have.
        </Text>

        <TextHeading>For more information:</TextHeading>
        <ul>
          <li>
            Call{' '}
            <Link href="tel:1-855-395-3248">
              1-855-EYLEA4U (1-855-395-3248)
            </Link>
            , Option 4, Monday–Friday, 9 AM–8 PM Eastern Time
          </li>
          <li>
            Visit{' '}
            <Link href="https://EYLEA4Ueportal.com">EYLEA4Ueportal.com</Link> or{' '}
            <Link href="https://hcp.eylea.us/eylea4u/patient-support">
              hcp.eylea.us/eylea4u/patient-support
            </Link>
          </li>
          <li>
            Fax at <Link href="tel:1-888-335-3264">1-888-335-3264</Link>
          </li>
        </ul>
        <Text>
          <LinkBold href="https://eyleahcp.us/s/eyleahd/resource-center">
            Click here
          </LinkBold>{' '}
          to learn more about reimbursement support and download helpful
          resources.
        </Text>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Resources;
