/*
  All Column Widths should add up to ~80-85%, which leaves a decent
  bit of space for the Icon columns in the table to occupy
*/

export const FIELDS = [
  { columnWidth: '14%', label: 'Insurer', value: 'controller' },
  { columnWidth: '13%', label: 'Health Plan', value: 'name' },
  { columnWidth: '8%', label: 'Status', value: 'coverageStatus' },
  { columnWidth: '8%', label: 'Step Edit', value: 'stepStatus' },
  { columnWidth: '8%', label: 'Step Statement', value: 'stepStatement' },
  {
    columnWidth: '8%',
    label: 'PA Required',
    value: 'paRequired',
  },
  {
    columnWidth: '8%',
    label: 'Reauthorization Required',
    value: 'reauthorizationRequired',
  },
  {
    columnWidth: '8%',
    label: '20/50 DME Exception',
    value: 'dmeExceptionCriteria',
  },
  {
    columnWidth: '8%',
    label: '# of Injections Prior to EYLEA Transition',
    value: 'dosageCriteria',
  },
];

export const HD_FIELDS = [
  { columnWidth: '14%', label: 'Insurer', value: 'controller' },
  { columnWidth: '14%', label: 'Health Plan', value: 'name' },
  { columnWidth: '9%', label: 'Status', value: 'coverageStatus' },
  { columnWidth: '9%', label: 'Step Edit', value: 'stepStatus' },
  { columnWidth: '9%', label: 'Step Statement', value: 'stepStatement' },
  {
    columnWidth: '9%',
    label: 'PA Required',
    value: 'paRequired',
  },
  {
    columnWidth: '9%',
    label: 'Reauthorization Required',
    value: 'reauthorizationRequired',
  },
  {
    columnWidth: '9%',
    label: 'EYLEA HD Transition',
    value: 'dosageCriteria',
  },
];
