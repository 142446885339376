import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import CoverageSelection from './CoverageSelection';
import CoverageResults from './CoverageResults';

const Coverage = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/results`} component={CoverageResults} />
      <Route path={path} component={CoverageSelection} />
    </Switch>
  );
};

export default Coverage;
