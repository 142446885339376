export const globalTransition = (...props) =>
  props.map((prop) => `${prop} 200ms ease`).join(', ');

/**
 * Calculates the height of the view area in the application by
 * subtracting pixel values from the full view height of the window
 * @param  {...string} subtractions pixel strings for amounts to subtract from the view height
 */
export const calcViewArea = (...subtractions) => {
  const subtractString = subtractions.map((s) => ` - ${s}`).join('');
  return `calc(100vh${subtractString})`;
};
