import * as colors from '../styles/colors';
import {
  FIELDS,
  HD_FIELDS,
} from '../components/PolicyList/PolicyList.constants';

export * as regex from './regex';

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const INDICATION_OPTIONS = [
  {
    value: 413,
    label: 'Wet AMD',
  },
  {
    value: 379,
    label: 'DME',
  },
  {
    value: 415,
    label: 'DR',
  },
  {
    value: 425,
    label: 'MEfRVO',
  },
];

export const EYLEA_ID = 158321;
export const EYLEA_HD_ID = 252886;

export const MEDICATION_OPTIONS = [
  {
    value: EYLEA_HD_ID,
    label: 'EYLEA HD',
    indications: INDICATION_OPTIONS.filter((item) => item.value !== 425),
    color: colors.green,
    fields: HD_FIELDS,
  },
  {
    value: EYLEA_ID,
    label: 'EYLEA',
    indications: INDICATION_OPTIONS,
    color: colors.teal,
    fields: FIELDS,
  },
];

export const CHANNEL_OPTIONS = [
  {
    label: 'Commercial',
    value: 1,
  },
  {
    label: 'Medicare Advantage',
    value: 2,
  },
  {
    label: 'State Medicaid',
    value: 3,
  },
  {
    label: 'Managed Medicaid',
    value: 5,
  },
  {
    label: 'Health Exchange',
    value: 4,
  },
];

export const TERRITORIES = [
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'North Marianas',
    value: 'MP',
  },
  {
    label: 'US Virgin Islands',
    value: 'VI',
  },
];

export const PRESCRIBING_INFORMATION = [
  {
    label: 'EYLEA HD Prescribing Information',
    value: 'https://www.regeneron.com/downloads/eyleahd_fpi.pdf',
  },
  {
    label: 'EYLEA Prescribing Information',
    value: 'https://www.regeneron.com/downloads/eylea_fpi.pdf',
  },
];
