import styled from 'styled-components';
import { bold, h3 } from '../styles/typography';

const PageSubheading = styled.h3`
  ${h3};
  ${bold};
  color: ${({ theme }) => theme.foreground};
  margin-bottom: 1em;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

export default PageSubheading;
