import { useCallback, useState } from 'react';

// this is heavily inspired by the setup of
// Apollo GraphQL's `useLazyQuery` hook
const useApiMethod = (apiMethod) => {
  const [called, setCalled] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handler = useCallback(
    async (...args) => {
      if (loading) return;
      try {
        setCalled(true);
        setLoading(true);
        const result = await apiMethod(...args);
        if (result) setData(result);
        setError(null);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [apiMethod, loading]
  );

  const reset = () => {
    setCalled(false);
    setData(null);
    setError(null);
    setLoading(false);
  };

  return {
    apiMethod: handler,
    called,
    data,
    error,
    loading,
    reset,
  };
};

export default useApiMethod;
