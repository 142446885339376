import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  CloseIcon,
  ForgotLink,
  LoginBox,
  LoginButton,
  LoginInput,
  LoginLabel,
  Subheader,
} from './LoginForm.components';
import { email as emailRegex } from '../../constants/regex';
import { useAuthContext } from '../../providers/AuthProvider';

const ForgotPasswordForm = () => {
  const history = useHistory();
  const { sendPasswordResetEmail } = useAuthContext();

  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const isEmailValid = emailRegex.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!isEmailValid) return;
    try {
      await sendPasswordResetEmail(email);
      setResetEmailSent(true);
    } catch (err) {
      // handle "user not found" different from other errors
      // to prevent abusing this to fish for valid emails...
      // UNLESS Navisync tells us specifically to do otherwise.
      if (err.code === 'auth/user-not-found') {
        console.log('Email not found');
        setResetEmailSent(true);
      } else {
        console.error(err);
      }
    }
  };

  const returnToLogin = () => {
    history.replace('/login');
  };

  const showEmailError = submitted && !isEmailValid;

  if (!resetEmailSent) {
    return (
      <LoginBox onSubmit={handleSubmit}>
        <LoginLabel>ENTER USERNAME (Email)</LoginLabel>
        <LoginInput
          error={showEmailError}
          inputProps={{
            onChange: (e) => setEmail(e.target.value),
            placeholder: 'Email Address',
            type: 'text',
            value: email,
          }}
        />
        <ForgotLink>Send me reset email.</ForgotLink>
        <LoginButton disabled={showEmailError}>Submit</LoginButton>
      </LoginBox>
    );
  } else {
    return (
      <LoginBox>
        <CloseIcon onClick={returnToLogin}>X</CloseIcon>
        <Subheader>Your request has been sent.</Subheader>
        <Subheader>Check your email for reset details.</Subheader>
      </LoginBox>
    );
  }
};

export default ForgotPasswordForm;
