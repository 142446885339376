import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import BrowserSupportModal from './components/BrowserSupportModal';
import AppProvider from './providers/AppProvider';
import AuthProvider from './providers/AuthProvider';
import FirebaseProvider from './providers/FirebaseProvider';
import GlobalStyle from './styles/GlobalStyle';
import { lightTheme } from './styles/themes';
import Routes from './routes';

import Layout from './components/Layout';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TAG}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_GA_TAG}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </Helmet>
      <FirebaseProvider>
        <AuthProvider>
          <AppProvider>
            <ThemeProvider theme={lightTheme}>
              <GlobalStyle />
              <Router>
                <Layout>
                  <Routes />
                </Layout>
              </Router>
              <BrowserSupportModal />
            </ThemeProvider>
          </AppProvider>
        </AuthProvider>
      </FirebaseProvider>
    </HelmetProvider>
  );
}

export default App;
