import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import * as Pages from '../pages';
import * as roles from '../constants/roles';
import routes from '../constants/routes';
import { useAuthContext } from '../providers/AuthProvider';

const Routes = () => {
  const { user } = useAuthContext();
  const rootRedirect = user?.role === roles.RBM ? routes.admin : routes.home;

  return (
    <Switch>
      <AuthenticatedRoute path={routes.home}>
        <Pages.Home />
      </AuthenticatedRoute>
      <UnauthenticatedRoute path={routes.login}>
        <Pages.Login />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path={routes.findCoverage}>
        <Pages.Coverage />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={routes.findPolicy}>
        <Pages.Policy />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={routes.findPriorAuth}>
        <Pages.PriorAuth />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={routes.userGuide}>
        <Pages.UserGuide />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={routes.resources}>
        <Pages.Resources />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path={routes.admin}
        roles={[roles.RBM, roles.SUPER_USER]}
      >
        <Pages.Admin />
      </AuthenticatedRoute>
      <Route path="/" exact>
        <Redirect to={rootRedirect} />
      </Route>
      <AuthenticatedRoute>
        <Pages.NotFound />
      </AuthenticatedRoute>
      <UnauthenticatedRoute>
        <Redirect to={routes.login} />
      </UnauthenticatedRoute>
    </Switch>
  );
};

export default Routes;
