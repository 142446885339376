import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

const getControlText = ({ disabled, selected }) => {
  if (disabled && selected) {
    return 'Selected';
  }
  if (!disabled && selected) {
    return 'REMOVE';
  }
  return 'ADD';
};

const ResultItemWrapper = styled.div`
  align-items: center;
  color: ${colors.greyscale[500]};
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  margin: 4px 0;
`;
const SelectControl = styled.div`
  ${typography.bold};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  margin-left: 4px;
  text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
`;

const ResultItem = ({ disabled, label, onSelect, selected }) => {
  const controlText = getControlText({ disabled, selected });

  const handleSelect = () => {
    if (disabled) return;
    onSelect();
  };

  return (
    <ResultItemWrapper>
      {label}
      <SelectControl disabled={disabled} onClick={handleSelect}>
        {controlText}
      </SelectControl>
    </ResultItemWrapper>
  );
};

ResultItem.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default ResultItem;
