import styled from 'styled-components';

import Button from '../../components/Button';
import Input from '../../components/Input';
import { linkStyles } from '../../components/Link';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

export const ErrorText = styled.div`
  ${typography.body};
  color: ${({ theme }) => theme.error};
  width: 400px;
  margin-bottom: 8px;
`;

export const LoginBox = styled.form`
  background-color: ${({ theme }) => theme.background};
  border: 8px solid ${colors.lightBlue};
  border-radius: 16px;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 16px 30px;
  width: 400px;
  & > * {
    margin: 4px 0;
  }
`;

export const LoginLabel = styled.div`
  ${typography.regular};
  ${typography.h3};
  color: ${({ theme }) => theme.foreground};
`;

// override default semantic-ui styles
export const LoginInput = styled(Input)`
  width: 100%;
  font-size: 0.875rem;
  padding: 16px;
`;

export const ForgotLink = styled.div`
  ${typography.caption};
  line-height: 0.875rem;
  ${(props) => props.onClick && linkStyles};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'normal')};
`;
export const HelpLink = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${typography.body};
`;
export const LoginButton = styled(Button).attrs({
  type: 'submit',
})`
  &:disabled {
    background-color: ${colors.fadedBlue};
  }
`;
export const Subheader = styled.div`
  width: 100%;
`;
export const CloseIcon = styled.div`
  ${typography.bold};
  cursor: pointer;
  color: ${({ theme }) => theme.foreground};
  margin-left: auto;
`;
