import { size as sizeUtil } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import image from '../assets/images/loading.png';

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  ${(props) => sizeUtil(props.size)};
  background-image: url(${image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: ${spinAnimation} 1.5s infinite linear;
`;

const LoadingSpinner = ({ size }) => {
  return <SpinnerContainer size={size} />;
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
};

LoadingSpinner.defaultProps = {
  size: 150,
};

export default LoadingSpinner;
