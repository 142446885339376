import { useState } from 'react';

const useTableSort = () => {
  const [sortBy, setSortBy] = useState('');
  const [order, setOrder] = useState('');

  const setSort = (field) => {
    if (sortBy === field) {
      if (order === 'asc') {
        setOrder('desc');
      } else if (order === 'desc') {
        setOrder('');
      } else {
        setOrder('asc');
      }
    } else {
      setSortBy(field);
      setOrder('asc');
    }
  };

  const getSortForField = (field) => {
    return sortBy === field ? order : '';
  };

  return {
    getSortForField,
    order,
    setSort,
    sortBy,
  };
};

export default useTableSort;
