import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../constants/routes';
import * as userRoles from '../constants/roles';
import { childrenPropType } from '../propTypes';
import { useAuthContext } from '../providers/AuthProvider';
import { backgroundStyles } from '../styles/constants';

/**
 * While loading the user authentication status from Firebase,
 * show a blank white page to avoid any ugly staggered rendering
 * of the authenticated page elements.
 */
const BlankWhitePage = styled.div`
  ${backgroundStyles};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const AuthenticatedRoute = ({ children, roles, ...rest }) => {
  const { user, loading } = useAuthContext();

  const render = () => {
    if (loading) {
      return <BlankWhitePage />;
    }

    return user && roles.includes(user.role) ? (
      children
    ) : (
      <Redirect to={routes.login} />
    );
  };

  return <Route {...rest} render={render} />;
};

AuthenticatedRoute.propTypes = {
  children: childrenPropType,
  roles: PropTypes.arrayOf(PropTypes.string),
};

AuthenticatedRoute.defaultProps = {
  roles: [userRoles.GENERAL_USER, userRoles.RBM, userRoles.SUPER_USER],
};

export default AuthenticatedRoute;
