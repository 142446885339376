import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_NPPW_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_NPPW_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_NPPW_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_NPPW_FIREBASE_APP_ID,
  storageBucket: process.env.REACT_APP_NPPW_FIREBASE_STORAGE_BUCKET,
};

class Fire {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth;
    this.firestore = firebase.firestore();
    this.functions = firebase.functions();
    this.storage = firebase.storage();
    this.analytics = firebase.analytics();
  }
}

const Firebase = new Fire();

export default Firebase;
