import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StateData from 'react-usa-map/src/data/usa-map-dimensions';

import {
  columnWidths,
  EMPTY_PRACTICE,
  tableLeftSpacing,
} from './PracticeList.constants';
import Input from '../Input';
import TextButton from '../TextButton';
import {
  ListBodyRow,
  ListBodyRowContent,
  DataColumn,
  LIST_PADDING_X,
} from '../List';
import { practicePropType } from '../../propTypes';
import * as regex from '../../constants/regex';

const STATE_ABBREVIATIONS = Object.keys(StateData());

const StyledRow = styled(ListBodyRow)`
  padding-left: CALC(${LIST_PADDING_X} + ${tableLeftSpacing});
  margin-bottom: 8px;
`;

const RowContent = styled(ListBodyRowContent)`
  margin-left: 0;
  margin-right: 0;
`;
const inputMarginOffset = '8px';
const StyledInput = styled(Input)`
  & input {
    margin: 4px;
    max-width: calc(100% - ${inputMarginOffset});
  }
`;

const PracticeForm = ({
  initialValue = EMPTY_PRACTICE,
  isSaving,
  onSave,
  onCancel,
}) => {
  const [practice, setPractice] = useState(initialValue);
  const [isFormValid, setIsFormValid] = useState(false);
  const setPracticeField = (fieldName) => (event) =>
    setPractice({
      ...practice,
      [fieldName]: event.target.value,
    });

  useEffect(() => {
    setIsFormValid(
      [
        !!practice.displayName,
        !!practice.address1,
        !!practice.city,
        !!practice.usState &&
          STATE_ABBREVIATIONS.includes(practice.usState.toUpperCase()),
        !!practice.zip && regex.zipCode.test(practice.zip),
        !!practice.phone && regex.phone.test(practice.phone),
      ].every(Boolean)
    );
  }, [practice]);

  const handleSave = () => {
    if (!isFormValid) return;
    onSave(practice);
  };
  const renderInput = (fieldName) => (
    <DataColumn
      width={`calc(${columnWidths[fieldName]} - ${inputMarginOffset})`}
    >
      <StyledInput
        inputProps={{
          onChange: setPracticeField(fieldName),
          value: practice[fieldName],
        }}
      />
    </DataColumn>
  );

  return (
    <StyledRow>
      <RowContent>
        {renderInput('displayName')}
        {renderInput('address1')}
        {renderInput('address2')}
        {renderInput('city')}
        {renderInput('usState')}
        {renderInput('zip')}
        {renderInput('phone')}
        <DataColumn justify="center" width={columnWidths.editButtons}>
          <TextButton disabled={isSaving} onClick={onCancel}>
            Cancel
          </TextButton>
          &nbsp;|&nbsp;
          <TextButton disabled={!isFormValid || isSaving} onClick={handleSave}>
            Save
          </TextButton>
        </DataColumn>
        <DataColumn width={columnWidths.expandButton} />
      </RowContent>
    </StyledRow>
  );
};

PracticeForm.propTypes = {
  initialValue: practicePropType,
  isSaving: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default PracticeForm;
