import React, { useState } from 'react';
import styled from 'styled-components';

import { usePracticeApi } from '../api/practice';
import * as Icons from '../components/Icons';
import { linkStyles } from '../components/Link';
import PracticeList from '../components/PracticeList';
import UserList from '../components/UserList';
import PageHeading from '../components/PageHeading';
import PageWrapper from '../components/PageWrapper';
import * as roles from '../constants/roles';
import useApiMethod from '../hooks/useApiMethod';
import { useAppContext } from '../providers/AppProvider';
import { useAuthContext } from '../providers/AuthProvider';
import * as typography from '../styles/typography';

const HeadingRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & > div {
    max-width: 33.3%;
  }
`;
const StyledPageHeading = styled(PageHeading)`
  text-align: center;
`;
const PolicyVisibilityToggleWrapper = styled.div`
  ${typography.body};
  ${typography.bold};
  align-items: center;
  display: flex;
  flex-shrink: 1;
  justify-content: flex-end;
  & > * {
    margin-left: 8px;
  }
`;
const ToggleIconWrapper = styled.div`
  cursor: pointer;
  margin-bottom: -4px;
`;
const ListControlRow = styled.div`
  ${typography.h6};
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;
const ListControlRowItem = styled.div`
  display: flex;
  justify-content: ${(props) => props.align};
  width: 33.3%;
`;
const Strong = styled.span`
  ${typography.bold};
`;
const TextButton = styled.span`
  ${linkStyles};
  color: ${({ theme }) => theme.foreground};
  text-decoration: underline;
`;

const roleText = {
  [roles.RBM]: {
    heading: 'Practices',
    newButton: 'PRACTICE',
  },
  [roles.SUPER_USER]: {
    heading: 'Office',
    newButton: 'NEW USER',
  },
};

const AdminPage = () => {
  const [showNewRow, setShowNewRow] = useState(false);

  const { SetAllPoliciesVisibilityForPractice } = usePracticeApi();
  const {
    apiMethod: setAllPolicyVisibility,
    loading: visibilityToggleLoading,
  } = useApiMethod(SetAllPoliciesVisibilityForPractice);
  const { practice, refetchPractice } = useAppContext();
  const { user } = useAuthContext();
  const pageText = roleText[user.role];

  const ToggleIcon = practice?.allPoliciesVisible
    ? Icons.ToggleOn
    : Icons.ToggleOff;

  const handleToggleAllPolicyVisibility = async () => {
    if (visibilityToggleLoading) return;
    await setAllPolicyVisibility(practice.id, !practice.allPoliciesVisible);
    refetchPractice();
  };

  return (
    <PageWrapper>
      <HeadingRow>
        <div />
        <StyledPageHeading>Manage My {pageText.heading}</StyledPageHeading>
        <PolicyVisibilityToggleWrapper>
          {user.role === roles.SUPER_USER && (
            <>
              All health plans viewable to General Users
              <ToggleIconWrapper onClick={handleToggleAllPolicyVisibility}>
                {ToggleIcon}
              </ToggleIconWrapper>
            </>
          )}
        </PolicyVisibilityToggleWrapper>
      </HeadingRow>
      <ListControlRow>
        <ListControlRowItem />
        <ListControlRowItem align="center">
          <Strong>{user.displayName || user.email}</Strong>
          &nbsp;({roles.ROLE_TEXT[user.role]})
        </ListControlRowItem>
        <ListControlRowItem align="flex-end">
          <TextButton disabled={showNewRow} onClick={() => setShowNewRow(true)}>
            + ADD {pageText.newButton}
          </TextButton>
        </ListControlRowItem>
      </ListControlRow>
      {user.role === roles.SUPER_USER && (
        <UserList
          onCloseNewRow={() => setShowNewRow(false)}
          showNewRow={showNewRow}
        />
      )}
      {user.role === roles.RBM && (
        <PracticeList
          onCloseNewRow={() => setShowNewRow(false)}
          showNewRow={showNewRow}
        />
      )}
    </PageWrapper>
  );
};

export default AdminPage;
