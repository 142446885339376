import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import ReactUSAMap from 'react-usa-map';

import * as colors from '../styles/colors';

const StyledContainer = styled.div`
  &.enabled {
    path {
      pointer-events: all;
    }
    path:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  svg {
    max-width: 100%;
  }
`;

const USAMap = ({ value, onClickState, disabled }) => {
  const handleClick = (event) => {
    if (!disabled) {
      let state = event.target.dataset.name;
      // https://github.com/gabidavila/react-usa-map/issues/43
      if (state === 'DC') {
        state = 'DC2';
      }
      onClickState(state);
    }
  };

  const customConfig = {
    [value]: {
      fill: colors.darkBlue,
    },
  };

  return (
    <StyledContainer className={disabled ? null : 'enabled'}>
      <ReactUSAMap
        height={400}
        title="Choose State for Coverage"
        customize={customConfig}
        onClick={handleClick}
      />
    </StyledContainer>
  );
};

USAMap.propTypes = {
  onClickState: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

USAMap.defaultProps = {
  value: undefined,
  disabled: false,
};

export default USAMap;
