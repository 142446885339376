import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Icons from '../Icons';
import Input from '../Input';
import { linkStyles } from '../Link';
import * as typography from '../../styles/typography';
import { boxShadowStandard } from '../../styles/constants';
import { darkTheme } from '../../styles/themes';

const GoButton = styled.span`
  ${typography.bold};
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  box-shadow: ${boxShadowStandard};
  color: ${({ theme }) => theme.text};
  cursor: default;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: -8px;
  top: -8px;
  z-index: 9;
`;
const FieldsPane = styled.div.attrs({
  theme: darkTheme,
})`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.foreground};
  padding: 18px 12px;
  position: relative;
  min-width: 260px;
`;
const SearchPane = styled.form`
  background-color: ${({ theme }) => theme.background};
  padding: 18px 12px;
  width: 220px;
`;

const FiltersIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 8px;
`;
const FieldLabel = styled.div.attrs({
  theme: darkTheme,
})`
  ${linkStyles};
  ${typography.h6};
  ${typography.bold};
  color: ${(props) => props.selected && props.theme.accent};
  line-height: 2rem;
  width: fit-content;
`;

const PolicyFilterMenu = ({ fields, activeFilters, onClose, onSetFilter }) => {
  const [activeField, setActiveField] = useState(null);
  const [filterInputValue, setFilterInputValue] = useState('');

  const selectField = (e, value) => {
    setActiveField(value);
  };
  const handleSetFilter = (e) => {
    e?.preventDefault();
    if (!filterInputValue) return;

    onSetFilter(activeField, filterInputValue);
    setActiveField(null);
    setFilterInputValue('');
    onClose();
  };

  const getFieldSelectedState = (field) => {
    if (activeField) return activeField === field;
    return !!activeFilters[field];
  };

  const handleChangeInput = useCallback(
    (e) => setFilterInputValue(e.target.value),
    [setFilterInputValue]
  );

  return (
    <ContentWrapper onClick={(e) => e.stopPropagation()}>
      {!!activeField && (
        <SearchPane onSubmit={handleSetFilter}>
          <Input
            iconRight={<GoButton onClick={handleSetFilter}>GO&nbsp;</GoButton>}
            inputProps={{
              autoFocus: true,
              onChange: handleChangeInput,
              value: filterInputValue,
            }}
          />
        </SearchPane>
      )}
      <FieldsPane>
        <FiltersIcon onClick={onClose}>{Icons.Filters}</FiltersIcon>
        {fields.map(({ label, value }) => (
          <FieldLabel
            key={value}
            onClick={(e) => selectField(e, value)}
            selected={getFieldSelectedState(value)}
          >
            {label}
          </FieldLabel>
        ))}
      </FieldsPane>
    </ContentWrapper>
  );
};

PolicyFilterMenu.propTypes = {
  fields: PropTypes.array,
  activeFilters: PropTypes.object,
  onClose: PropTypes.func,
  onSetFilter: PropTypes.func,
};

export default PolicyFilterMenu;
