import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Firebase from '../services/firebase';

const DEFAULT_STATE = { ...Firebase };

export const FirebaseContext = React.createContext(DEFAULT_STATE);

export const useFirebaseContext = () => useContext(FirebaseContext);

const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={DEFAULT_STATE}>
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseProvider;
