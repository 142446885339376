import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FIELDS } from './PolicyList.constants';
import { policyPropType } from '../../propTypes';

import FullWidthWrapper from '../FullWidthWrapper';
import { linkStyles } from '../Link';
import LoadingSpinner from '../LoadingSpinner';

import {
  ListTitleBar,
  ListTitleHeader,
  List,
  ListBodyRow,
  ListHeaderRow,
  IconColumn,
  DataColumn,
  SortIcon,
  LIST_PADDING_X,
} from '../List';
import * as Icons from '../Icons';
import PolicyFilterMenu from './PolicyFilterMenu';
import PolicyListRow from './PolicyListRow';
import { filterPolicies, filterPoliciesByProductId } from './filterPolicies';
import sortPolicies from './sortPolicies';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';
import useTableFilters from '../../hooks/useTableFilters';
import useTableSort from '../../hooks/useTableSort';
import { MEDICATION_OPTIONS } from '../../constants';

const StyledTitleBar = styled(ListTitleBar)`
  align-items: stretch;
  margin-bottom: 24px;
  padding: 0px;
`;
const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ProductIndicator = styled.div`
  width: ${LIST_PADDING_X};
  height: 100%;
  margin-right: ${LIST_PADDING_X};
  background-color: ${(props) => props.color || 'transparent'};
`;
const SortableHeaderWrapper = styled(DataColumn)`
  & > div {
    cursor: pointer;
  }
`;
const FilterControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 8px ${LIST_PADDING_X};
  & > div {
    margin-left: 16px;
  }
`;
const ClearFiltersButton = styled.div`
  ${linkStyles};
  ${typography.bold};
  color: ${colors.white};
`;
const FiltersIcon = styled.div`
  ${typography.caption};
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  & svg {
    stroke: ${(props) => props.active && colors.lightYellow};
  }
`;
const ProductTitle = styled.div`
  margin: 35px calc(${LIST_PADDING_X} * 2) 0;
  color: ${(props) => props.color};
  ${typography.h4};
  ${typography.bold};
`;

const SortableHeader = ({ onClick, order, text, width }) => {
  return (
    <SortableHeaderWrapper width={width}>
      {order && (
        <SortIcon onClick={onClick} order={order}>
          {Icons.ChevronUp}
        </SortIcon>
      )}
      <div onClick={onClick}>{text}</div>
    </SortableHeaderWrapper>
  );
};
SortableHeader.propTypes = {
  onClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc', '']),
  text: PropTypes.string,
  width: PropTypes.string,
};

const PolicyList = ({
  loading,
  noResultsText,
  policies,
  showMeta,
  showVisibleToPractice,
  title,
  product,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const { getSortForField, order, setSort, sortBy } = useTableSort(policies);
  const { clearAllFilters, filters, setFilterForField } = useTableFilters();

  const filterFields = product?.fields ?? FIELDS;

  const filteredPolicies = useMemo(() => {
    const filteredPolicies = filterPolicies(policies, filters);
    return sortPolicies(filteredPolicies, sortBy, order);
  }, [policies, filters, sortBy, order]);
  const isTableFiltered = useMemo(() => {
    return Object.values(filters).some(Boolean);
  }, [filters]);

  useEffect(() => {}, [filters]);

  const renderHeaderCell = ({ columnWidth, label, value }) => (
    <SortableHeader
      key={value}
      onClick={() => setSort(value)}
      order={getSortForField(value)}
      text={label}
      width={columnWidth}
    />
  );

  const renderTableForProduct = (product, filteredPolicies) => (
    <List>
      <ListHeaderRow>
        {showVisibleToPractice && <IconColumn>Viewable to Practice</IconColumn>}
        <IconColumn>Show on Homepage</IconColumn>
        {product.fields.map(renderHeaderCell)}
      </ListHeaderRow>
      {!!filteredPolicies.length &&
        filteredPolicies.map((policy, index) => (
          <PolicyListRow
            product={product}
            policy={policy}
            showMeta={showMeta}
            showVisibleToPractice={showVisibleToPractice}
            key={index}
          />
        ))}
      {!filteredPolicies.length && noResultsText && (
        <ListBodyRow>
          <div>{noResultsText}</div>
        </ListBodyRow>
      )}
    </List>
  );

  const renderAllTables = () => {
    return MEDICATION_OPTIONS.map((option) => (
      <React.Fragment key={option.value}>
        <ProductTitle color={option.color}>{option.label}</ProductTitle>
        {renderTableForProduct(
          option,
          filterPoliciesByProductId(filteredPolicies, option.value)
        )}
      </React.Fragment>
    ));
  };

  return (
    <FullWidthWrapper>
      <StyledTitleBar>
        <ProductWrapper>
          <ProductIndicator color={product?.color} />
          <ListTitleHeader>{title}</ListTitleHeader>
        </ProductWrapper>
        <FilterControlsWrapper>
          {isTableFiltered && (
            <ClearFiltersButton
              activeColor={colors.lightYellow}
              onClick={clearAllFilters}
            >
              SHOW ALL
            </ClearFiltersButton>
          )}
          <FiltersIcon
            active={isTableFiltered}
            onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
          >
            {Icons.Filters}
            FILTER
            {isFilterMenuOpen && (
              <PolicyFilterMenu
                fields={filterFields}
                activeFilters={filters}
                onClose={() => setIsFilterMenuOpen(false)}
                onClearFilter={clearAllFilters}
                onSetFilter={setFilterForField}
              />
            )}
          </FiltersIcon>
        </FilterControlsWrapper>
      </StyledTitleBar>
      {loading && <LoadingSpinner size={100} />}
      {!loading && product
        ? renderTableForProduct(product, filteredPolicies)
        : renderAllTables()}
    </FullWidthWrapper>
  );
};

PolicyList.propTypes = {
  loading: PropTypes.bool,
  noResultsText: PropTypes.string,
  policies: PropTypes.arrayOf(policyPropType).isRequired,
  showMeta: PropTypes.bool,
  showVisibleToPractice: PropTypes.bool,
  title: PropTypes.string,
  product: PropTypes.object,
};

PolicyList.defaultProps = {
  loading: false,
  showMeta: false,
  showVisibleToPractice: true,
};

export default PolicyList;
