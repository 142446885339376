import React from 'react';
import PropTypes from 'prop-types';

import * as colors from '../styles/colors';
import ReactSelect from 'react-select';

const selectStyles = {
  control: (
    provided,
    { isFocused, hasValue, isDisabled, isPi, selectProps }
  ) => ({
    ...provided,
    cursor: 'pointer',
    border: 0,
    minHeight: '20px',
    borderColor:
      isFocused || hasValue ? colors.darkBlue : colors.greyscale[600],
    borderRadius: 0,
    backgroundColor: (() => {
      if (selectProps.backgroundColor) {
        return selectProps.backgroundColor;
      } else if (isDisabled) {
        return colors.greyscale[200];
      } else if (isFocused || hasValue) {
        return colors.darkBlue;
      }
      return colors.white;
    })(),
    color: isFocused || hasValue ? colors.white : colors.greyscale[500],
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    transition: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.white,
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: 'fit-content',
    height: '1.5rem',
  }),
  indicatorsContainer: (provided, { isDisabled }) => ({
    ...provided,
    display: 'block',
  }),
  dropdownIndicator: (provided, { isFocused, hasValue }) => ({
    ...provided,
    padding: '2px',
    display: 'block',
    // display: isFocused || hasValue ? 'none' : 'block',
  }),
  placeholder: (provided, { isFocused }) => ({
    ...provided,
    color: colors.greyscale[500],
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    padding: 0,
    margin: 0,
    border: `1px solid ${colors.greyscale[600]}`,
    borderTop: 0,
    boxShadow: 'none',
  }),
  container: (provided) => ({
    width: '100%',
    ...provided,
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    margin: 0,
    background: colors.white,
    color: isFocused ? data.color ?? colors.darkBlue : colors.greyscale[500],
    fontWeight: 'bold',
    cursor: 'pointer',
  }),
};

const SelectPi = ({
  options,
  placeholder,
  value,
  valueProp,
  onChange,
  isDisabled,
  ...rest
}) => {
  const handleChange = (option) => {
    valueProp ? onChange(option[valueProp]) : onChange(option);
    return window.open(option.value, '_blank');
  };
  const internalValue = valueProp
    ? options.find((o) => o[valueProp] === value)
    : value;

  return (
    <ReactSelect
      {...rest}
      value={internalValue}
      onChange={handleChange}
      styles={selectStyles}
      options={options}
      placeholder={placeholder}
      isClearable={false}
      isSearchable={false}
      isDisabled={isDisabled || options.length === 1}
    />
  );
};

SelectPi.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  valueProp: PropTypes.string,
};

SelectPi.defaultProps = {
  value: undefined,
  onChange: () => {},
  placeholder: undefined,
  isDisabled: false,
};

export default SelectPi;
