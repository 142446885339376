import routes from './routes';

export const siteMetadata = {
  [routes.login]: {
    title: 'EYLEA Payer Policy Portal',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: false,
  },
  [`${routes.login}/forgot`]: {
    title: 'EYLEA Payer Policy Portal',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: false,
  },

  [routes.home]: {
    title: 'Home',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },

  [routes.findCoverage]: {
    title: 'Find Insurance Coverage',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },
  [`${routes.findCoverage}/results`]: {
    title: 'Results',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },

  [routes.findPolicy]: {
    title: 'Find a Medical Policy',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },

  [routes.findPriorAuth]: {
    title: 'Find a PA Form',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },

  [routes.userGuide]: {
    title: 'User Guide',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },

  [routes.resources]: {
    title: 'Resources',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },
  [routes.admin]: {
    title: 'Admin Settings',
    jobNumber: 'US.EHD.23.12.0050',
    lastUpdated: '01/2024',
    showBanners: true,
  },
};
