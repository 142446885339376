// sort falsy values to the bottom
const nullCompare = (a, b) => {
  if (!a && b) return 1;
  if (a && !b) return -1;
  return 0;
};

const sortPolicies = (policies, sortBy, order) => {
  if (!order || !sortBy) return policies;

  // spread to create a copy
  const sortedPolicies = [...policies].sort((a, b) => {
    if (!a[sortBy] || !b[sortBy]) return nullCompare(a[sortBy], b[sortBy]);
    return a[sortBy].localeCompare(b[sortBy]);
  });

  return order === 'asc' ? sortedPolicies : sortedPolicies.reverse();
};

export default sortPolicies;
