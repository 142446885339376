export const filterPolicies = (policies, filters) => {
  if (!Object.values(filters).some(Boolean)) {
    return policies;
  }
  const filteredPolicies = Object.keys(filters).reduce(
    (acc, field) => {
      return acc.filter((p) =>
        p[field]?.toLowerCase().includes(filters[field]?.toLowerCase())
      );
    },
    [...policies]
  );

  return filteredPolicies;
};

export const filterPoliciesByProductId = (policies, productId) => {
  return policies.filter((policy) => policy.productId === productId);
};
