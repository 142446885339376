import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import * as roles from '../constants/roles';
import { useAuthContext } from '../providers/AuthProvider';

import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';
import JumboButton from '../components/JumboButton';
import { darkTheme } from '../styles/themes';

const HomeCallToAction = ({ onClickButton }) => {
  const { user } = useAuthContext();

  const buttonLabel = {
    [roles.GENERAL_USER]: 'View Available Plans',
    [roles.RBM]: 'Add Plans to Your Favorites',
    [roles.SUPER_USER]: 'Add Plans to Your Practice Portal',
  }[user.role];

  return (
    <PageWrapper>
      <PageHeading>
        Welcome to the Payer Policy Portal for Eylea HD and Eylea!
      </PageHeading>
      <ThemeProvider theme={darkTheme}>
        <JumboButton onClick={onClickButton}>{buttonLabel}</JumboButton>
      </ThemeProvider>
    </PageWrapper>
  );
};

HomeCallToAction.propTypes = {
  onClickButton: PropTypes.func.isRequired,
};

export default HomeCallToAction;
