export const columnWidths = {
  displayName: '25%',
  email: '45%',
  editButtons: '14%',
  passwordReset: '16%',
};

export const EMPTY_USER = {
  displayName: '',
  email: '',
};
