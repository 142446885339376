import { useState } from 'react';

import { trimSearchInput } from '../helpers';

const useTableFilters = (config = {}) => {
  const { allowMultipleFilters = false } = config;

  const [filters, setFilters] = useState({});

  const clearAllFilters = () => {
    setFilters({});
  };
  const clearFilterForField = (field) => {
    setFilters({
      ...filters,
      [field]: null,
    });
  };
  const setFilterForField = (field, filterVal) => {
    if (allowMultipleFilters) {
      setFilters({
        ...filters,
        [field]: trimSearchInput(filterVal),
      });
    } else {
      setFilters({ [field]: trimSearchInput(filterVal) });
    }
  };

  return {
    clearAllFilters,
    clearFilterForField,
    filters,
    setFilterForField,
  };
};

export default useTableFilters;
