import styled from 'styled-components';
import { h1, bold } from '../styles/typography';

const PageHeading = styled.h1`
  ${h1}
  ${bold}
  color: ${({ theme }) => theme.foreground};
  margin-bottom: 1em;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

export default PageHeading;
