import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import * as roles from '../constants/roles';
import routes from '../constants/routes';
import { childrenPropType } from '../propTypes';
import { useAuthContext } from '../providers/AuthProvider';

const UnauthenticatedRoute = ({ children, ...rest }) => {
  const { user } = useAuthContext();
  const redirectTo = user?.role === roles.RBM ? routes.admin : routes.home;

  return (
    <Route
      {...rest}
      render={() => (!user ? children : <Redirect to={redirectTo} />)}
    />
  );
};

UnauthenticatedRoute.propTypes = {
  children: childrenPropType,
};

export default UnauthenticatedRoute;
