import { useState } from 'react';
import axios from 'axios';
import qs from 'query-string';
import FileDownload from 'js-file-download';

import { useAppContext } from '../providers/AppProvider';
import { useAuthContext } from '../providers/AuthProvider';
import { useFirebaseContext } from '../providers/FirebaseProvider';

const ENDPOINT = `https://${process.env.REACT_APP_HYDRA_URL}/priorauth?`;
const DOCENDPOINT = `https://api.mmitnetwork.com/Medical/v1/Notes/Documents/Lookup?`;

const mapPolicyIds = (policies) =>
  policies.map((policy) => ({
    id: policy.id,
    indicationId: policy.indicationId,
    productId: policy.productId,
  }));

const mapDocumentLinksToRequests = (documentData, headers) => {
  const docNames = documentData.map((d) => d.DocumentName);
  const requests = documentData.map(({ DocumentId }) =>
    axios.get(DOCENDPOINT, {
      headers,
      responseType: 'blob',
      params: { DocumentId },
    })
  );
  return { docNames, requests };
};

export const usePolicyApi = () => {
  const { user } = useAuthContext();
  const { setGlobalError } = useAppContext();
  const firebase = useFirebaseContext();
  const [authHeaders, setAuthHeaders] = useState(null);

  const fetchPolicyLinks = async (indication, selections, productName) => {
    try {
      const authToken = await firebase.auth().currentUser.getIdToken();
      const formularyId = selections.map((plan) => plan.formularyId);
      const queryString = qs.stringify({
        formularyId,
        indication,
        authToken,
        productName,
      });
      const { data: res } = await axios.get(ENDPOINT + queryString);

      const mappedResults = selections.map((selection) => {
        const match = res.data.find(
          (r) => selection.formularyId === parseInt(r.formularyId)
        );
        if (!match) return selection;
        return { ...selection, docs: match.data };
      });
      setAuthHeaders({
        Authorization: res.auth.Authorization,
        Accept: 'application/json',
      });
      return mappedResults;
    } catch (err) {
      console.log(err);
      setGlobalError({
        err: err,
        message: 'The server responded with a error, please try again later.',
      });
    }
  };

  const downloadPolicyDocuments = async (documentData) => {
    const { docNames, requests } = mapDocumentLinksToRequests(
      documentData,
      authHeaders
    );
    const responses = await axios.all(requests);
    const docs = responses.map(({ data }, index) => ({
      data,
      name: docNames[index],
    }));
    docs.forEach((d) => FileDownload(d.data, `${d.name}.pdf`));
  };

  const updateFavoritePolicies = async (policies) => {
    const mappedPolicies = mapPolicyIds(policies);
    return await firebase.firestore
      .collection('users')
      .doc(user.uid)
      .set({ policiesShownOnHomepage: mappedPolicies }, { merge: true });
  };

  const updateVisiblePolicies = async (policies) => {
    const mappedPolicies = mapPolicyIds(policies);
    return await firebase.firestore
      .collection('practices')
      .doc(user.practiceId)
      .set({ policies: mappedPolicies }, { merge: true });
  };

  return {
    downloadPolicyDocuments,
    fetchPolicyLinks,
    updateFavoritePolicies,
    updateVisiblePolicies,
  };
};
